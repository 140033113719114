import React from "react";

const TextModal = ({ text1, text2, varieties = [] }) => {
  return (
    <>
      <p
        style={{
          fontFamily: "var(--font-family)",
          fontSize: "1.2rem",
          lineHeight: "1.5rem"
        }}
      >
        {text1}
        <span
          style={{
            fontSize: "1.2rem",
            fontFamily: "var(--font-family)"
          }}
        >
          {varieties.length === 1 && ` - ${varieties[0]}`}
        </span>
      </p>
      {text2 !== undefined && (
        <p
          style={{
            fontSize: "1.2rem",

            fontFamily: "var(--font-family)"
          }}
        >
          {text2}
        </p>
      )}
    </>
  );
};

export default TextModal;
