import * as React from "react";
const EliminaCarrello = (props) => {
  const customClass = props.custom_class ? props.custom_class : "cls-1";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Livello_1"
      data-name="Livello 1"
      viewBox="0 0 85.04 85.04"
      {...props}
    >
      <defs></defs>
      <path
        d="M25.88 32.32H72L62.34 58.8a2.57 2.57 0 0 1-2.41 1.69H30.7a2.56 2.56 0 0 1-2.54-2.24l-4.84-38.73H13.08"
        className="cls-1"
      />
      <circle cx={59.16} cy={65.61} r={5.12} className="cls-1" />
      <circle cx={31} cy={65.61} r={5.12} className="cls-1" />
      <path d="M36.12 41.73h23.04M36.12 50.51h19.71" className="cls-1" />
      <circle
        cx={55.83}
        cy={25.72}
        r={11.41}
        style={{
          fill: "#e30613"
        }}
      />
      <path
        d="M49.42 25.72h12.82"
        style={{
          stroke: "#fff",
          strokeMiterlimit: 10,
          strokeWidth: 4,
          fill: "none"
        }}
      />
    </svg>
  );
};
export default EliminaCarrello;
