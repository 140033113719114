import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
import { setSearchbarWidth } from "../../redux/carousel/action";
import { connect } from "react-redux";

class NavList extends Component {
  state = {
    collapse: false,
    tooltip: false
  };

  toggle = (e) => {
    e.preventDefault();
    this.setState({ collapse: !this.state.collapse });
  };

  componentDidMount = () => {
    // this is for opening collapse for active link
    if (this.props.list.child) {
      const finalList = this.props.list.child.filter(
        (a) => !a.hasOwnProperty("child")
      );
      const threeList = this.props.list.child.filter((a) =>
        a.hasOwnProperty("child")
      );
      const finalThreelevel = [];
      if (threeList.length) {
        threeList.forEach((element) => {
          element.child.forEach((ele) => {
            finalThreelevel.push(ele.routepath);
          });
        });
      }
      if (
        (finalList.length &&
          finalList
            .map((a) => a.routepath)
            .includes(this.props.location.pathname)) ||
        (finalThreelevel &&
          finalThreelevel.includes(this.props.location.pathname))
      ) {
        this.setState({
          collapse: true
        });
      }
    }
  };

  render() {
    let dynamicList;

    const { list, setSearchbarW } = this.props;

    dynamicList = (
      <li
        className={this.props.mini ? "li_sidebar" : ""}
        id={list.name}
        style={{ position: "relative" }}
        onMouseEnter={() => {
          if (this.props.themeSetting === "on")
            this.setState({ tooltip: true });
        }}
        onMouseLeave={() => this.setState({ tooltip: false })}
      >
        {list.routepath ? (
          <NavLink
            to={list.routepath}
            className="nav-link main-list"
            onClick={(e) => {
              // forceDrawerMini();
              if (this.props.searchBarOpen) setSearchbarW();
              list.routepath && list.action(e);
            }}
          >
            {/* <i className={list.iconClass} /> */}
            <list.component />
            <p
              className={this.props.mini ? "fade_p" : ""}
              style={{ opacity: this.props.mini ? null : "1" }}
            >
              {list.name}
            </p>
          </NavLink>
        ) : (
          <div
            className="nav-link main-list"
            style={{
              cursor: "pointer",
              opacity: this.props.location.pathname !== "/leaflet" ? 0.5 : 1
            }}
            onClick={() => {
              if (
                this.props.location.pathname !== "/categories" &&
                this.props.location.pathname !== "/pages"
              )
                setSearchbarW();
            }}
          >
            <list.component />
            <p
              className={this.props.mini ? "fade_p" : ""}
              style={{ opacity: this.props.mini ? null : "1" }}
            >
              {list.name}
            </p>
          </div>
        )}
      </li>
    );

    return <Fragment>{dynamicList}</Fragment>;
  }
}

const mapStateToProps = (state) => {
  return {
    themeSetting: state.themeSetting.sidebarMiniValue,
    searchBarOpen: state.carousel.searchBarOpen
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setSearchbarW: () => {
      dispatch(setSearchbarWidth());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NavList);
