import { START_LOADING_PRICES, END_LOADING_PRICES, GET_PRICES } from "../type";
import * as services from "../../services/product.services";
import { getPagesFromLabel } from "../../helper/utility";
import { serverResponse } from "../../util/fakeServer";

export const getPricesAction = (str, isMobileFeed, array = -1) => async (
  dispatch
) => {
  let arr = array !== -1 ? array : [];

  if (arr.length === 0) {
    const pages = !isMobileFeed
      ? getPagesFromLabel(str.toString())
      : Array.from(Array(serverResponse.leaflet.pages.length).keys());
    pages.forEach((element) => {
      serverResponse.leaflet.pages
        .filter((el) => el.number === +element)
        .map((res) => res.interactivities.map((inter) => arr.push(inter.skul)));
    });
  }

  // let productsArray = serverResponse.pages[pages[0]].filter(
  //   (el) => el !== null
  // );

  // console.log("this is productsArray", productsArray);

  //   for (let index = 1; index < pages.length; index++) {
  //     productsArray = productsArray.concat(
  //       serverResponse.pages[pages[index]].filter((el) => el !== null)
  //     );
  //   }

  // const idListino = serverResponse.config.id_listino;

  if (arr.length > 0) {
    try {
      dispatch({ type: START_LOADING_PRICES });
      const {
        data: { data: res }
      } = await services.getPrices(arr, serverResponse.config.id_listino);

      dispatch({
        type: GET_PRICES,
        payload: res[0]
      });
    } catch (error) {
      //   dispatch({
      //     type: SET_ERROR,
      //     payload: error
      //   });
      dispatch({
        type: END_LOADING_PRICES
      });
    }
  }
};
