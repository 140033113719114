import React, { useEffect, useRef, useState } from "react";
import { Redirect } from "react-router-dom";
import "./login.css";
import { connect } from "react-redux";
import { loginJWT, signupJWT } from "../redux/authJWT/action";
import { serverResponse } from "../util/fakeServer";
import { useHistory, useLocation } from "react-router-dom";
import LoadingPages from "./Loading.pages";
import * as services from "../services/resetPassword.services";
import { language } from "../languageProvider/language/language";
import { defaultLanguage } from "../helper/utility";
import { interattivoLogo } from "../helper/constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { startLoadingJWT, endLoadingJWT } from "../redux/authJWT/action";

function Form(props) {
  const [option, setOption] = useState(1);
  const location = useLocation();

  const history = useHistory();

  const [errorReset, setErrorReset] = useState(null);
  const [feedMessage, setFeedMessage] = useState(false);
  const [form, setForm] = useState({
    email: "",
    password: "",
    name: "",
    surname: "",
    codice: "",
    address: null,
    phone: null
  });

  useEffect(() => {
    switch (location.pathname) {
      case "/register":
        setOption(2);
        break;
      case "/reset-psw":
        setOption(3);
        break;

      default:
        setOption(1);
    }
  }, []);

  const refEmail = useRef(null);
  const refName = useRef(null);
  const refSurname = useRef(null);
  const refPassword = useRef(null);
  const refCodiceCliente = useRef(null);
  // const refPhone = useRef(null);
  // const refAddress = useRef(null);

  const onChange = ({ name, value }) => {
    setForm({ ...form, [name]: value });
  };

  const resetAll = () => {
    setErrorReset(null);
    setFeedMessage(null);
  };
  const handleOnPress = () => {
    setErrorReset(null);
    if (!feedMessage) {
      if (option === 1) {
        props.loginJWT(form, history);
      }
      if (option === 2) {
        props.signupJWT(form);
      }
      if (option === 3) {
        const dataReset = {
          email: form.email,
          id_signboard: serverResponse.config.signboard_id,
          url: `${window.location.href.replace("reset-psw", "")}set-psw`,
          home: `${window.location.href.replace("reset-psw", "")}set-psw`
        };
        props.startLoadingJWT()
        services
          .resetPassword(dataReset)
          .then((res) => {
            switch (res.data.status) {
              case 200:
                setFeedMessage("Email inviata con successo");
                break;
              case 403:
                setErrorReset(language[defaultLanguage].email_non_presente);
                break;
              default:
                setErrorReset("Errore");
                break;
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => props.endLoadingJWT());
      }
    }
  };

  const handleKeyPress = (e) => { };

  useEffect(() => {
    document.addEventListener("keyup", handleKeyPress);

    return () => {
      document.removeEventListener("keyup", handleKeyPress);
    };
  }, []);

  if (
    !serverResponse.config.allow_login ||
    (props.authJWT.isAuthenticated && !props.authJWT.loadingProfile)
  ) {
    return <Redirect to="/leaflet" />;
  }

  if (props.authJWT.loadingProfile) {
    return <LoadingPages />;
  }

  return (
    <div className="global-wrapper">
      <div className="main-container">
        <img
          style={{ width: "150px" }}
          className="img-fluid"
          src={serverResponse.config.logo_full}
          alt="logo cliente"
        />
        <header>
          <div
            className={
              "header-headings " +
              (option === 1 ? "sign-in" : option === 2 ? "sign-up" : "forgot")
            }
          >
            <span>{language[defaultLanguage].effettua_login}</span>
            <span>{language[defaultLanguage].crea_account}</span>
            <span>{language[defaultLanguage].resetta_psw}</span>
          </div>
        </header>
        <ul className="options" style={{ listStyle: "none", padding: 0 }}>
          <li
            className={option === 1 ? "active" : ""}
            onClick={() => {
              resetAll();
              setOption(1);
              history.replace("/login");
            }}
          >
            {language[defaultLanguage].login}
          </li>
          <li
            className={option === 2 ? "active" : ""}
            onClick={() => {
              resetAll();
              setOption(2);
              history.replace("/register");
            }}
          >
            {language[defaultLanguage].registrati}
          </li>
          <li
            className={option === 3 ? "active" : ""}
            onClick={() => {
              resetAll();
              setOption(3);
              history.replace("/reset-psw");
            }}
          >
            {language[defaultLanguage].rec_psw}
          </li>
        </ul>
        <form
          className="account-form"
          onSubmit={(evt) => {
            evt.preventDefault();
            handleOnPress();
          }}
        >
          <div
            className={
              "account-form-fields " +
              (option === 1 ? "sign-in" : option === 2 ? "sign-up" : "forgot")
            }
          >
            <input
              value={form.email}
              ref={refEmail}
              id="email"
              name="email"
              type="email"
              placeholder={language[defaultLanguage].email}
              required
              onChange={(e) => {
                onChange({ name: "email", value: e.target.value });
              }}
            />
            <input
              value={form.password}
              ref={refPassword}
              id="password"
              name="password"
              type="password"
              placeholder={language[defaultLanguage].password}
              required={option === 1 || option === 2 ? true : false}
              disabled={option === 3 ? true : false}
              onChange={(e) => {
                onChange({ name: "password", value: e.target.value });
              }}
            />
            <input
              value={form.codice}
              ref={refCodiceCliente}
              name="codice_cliente"
              id="codice_cliente"
              type="text"
              placeholder={language[defaultLanguage].codice_cliente}
              disabled={option === 1 || option === 3 ? true : false}
              onChange={(e) => {
                onChange({ name: "codice", value: e.target.value });
              }}
            />
            <input
              value={form.name}
              ref={refName}
              name="name"
              id="name"
              type="text"
              placeholder={language[defaultLanguage].nome}
              required={option === 2 ? true : false}
              disabled={option === 1 || option === 3 ? true : false}
              onChange={(e) => {
                onChange({ name: "name", value: e.target.value });
              }}
            />
            <input
              ref={refSurname}
              value={form.surname}
              id="surname"
              name="surname"
              type="text"
              placeholder={language[defaultLanguage].cognome}
              required={option === 2 ? true : false}
              disabled={option === 1 || option === 3 ? true : false}
              onChange={(e) => {
                onChange({ name: "surname", value: e.target.value });
              }}
            />
          </div>
          {!feedMessage && !props.authJWT.feedbackMessage && (
            <button className="btn-submit-form mt-3" type="submit">
              {option === 1
                ? language[defaultLanguage].login
                : option === 2
                  ? language[defaultLanguage].registrati
                  : language[defaultLanguage].resetta_psw}
              {props.authJWT.loading && (
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              )}
              {!!props.authJWT.error && (
                <div style={{ color: "red" }}>
                  <p>{props.authJWT.error}</p>
                </div>
              )}
              {!!errorReset && <div style={{ color: "red" }}>{errorReset}</div>}
            </button>
          )}
          {feedMessage && (
            <p style={{ color: "#0008", width: "320px", textAlign: "center" }}>
              <>
                {feedMessage}

                <FontAwesomeIcon style={{ color: "green" }} icon={faCheck} />
              </>
            </p>
          )}
          {props.authJWT.feedbackMessage && (
            <p style={{ color: "#0008", width: "320px", textAlign: "center" }}>
              <>
                {props.authJWT.feedbackMessage}
                <FontAwesomeIcon style={{ color: "green" }} icon={faCheck} />
              </>
            </p>
          )}
        </form>
      </div>
      <div className="login_footer">
        <span style={{ fontSize: "0.7em", marginRight: "5px" }}>
          powered by
        </span>
        <img
          style={{
            width: "200px"
          }}
          className="img-fluid"
          src={interattivoLogo}
          alt="logo creator"
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    authJWT: state.authJWT
  };
};

export default connect(mapStateToProps, {
  loginJWT,
  signupJWT,
  startLoadingJWT,
  endLoadingJWT
})(Form);
