import React from "react";
import { Modal } from "reactstrap";
import { connect } from "react-redux";
import { closeInteractivityModal } from "../../redux/modal/action";
import { iconsObj, sendAnalyticsEvent } from "../../helper/utility";
import ModalBody from "reactstrap/lib/ModalBody";
import Row from "reactstrap/lib/Row";
import { isIOS } from "react-device-detect";
import Facebook from "../../assets/images/icons/fb.png";
import Whatsapp from "../../assets/images/icons/wa.png";
import Close from "../../assets/images/icons/close.svg";
import ContentModal from "./modalbody/ContentModal";
import Marker from "../leaflet/markers/Marker";
import { serverResponse } from "../../util/fakeServer";
import { gaEventLabel } from "../../settings/gaConfig";
import ManagerFactory from "../../managers/ManagerFactory";
import LoaderModal from "../common/LoaderModal";
import ModalFooter from "reactstrap/lib/ModalFooter";
import useCurrentWidth from "../../hooks/widthHook";
import { BREAKPOINT } from "../../helper/constant";
import { styles } from "./header.styles";

const ModalComponent = (props) => {
  const otherPropsModal = props.modalRedux.otherProps;
  const fakeServerConfig = props.fakeServer.config;
  const productUrl = serverResponse.config.shareFlyerURL;
  const manager = ManagerFactory.getManager();
  const width = useCurrentWidth();

  const shareOnFacebook = () => {
    sendAnalyticsEvent(
      gaEventLabel.share_product_facebook,
      String(otherPropsModal.info.item_id)
    );

    let fb_share_url = "https://www.facebook.com/share.php?u=";
    let page_url =
      productUrl + "/share/product.php?c=" + otherPropsModal.info.item_id;
    window.open(
      fb_share_url + encodeURIComponent(page_url),
      "_blank",
      "location=yes,height=570,width=520,scrollbars=yes,status=yes"
    );
  };

  const shareOnWhatsapp = () => {
    sendAnalyticsEvent(
      gaEventLabel.share_product_whatsapp,
      String(otherPropsModal.info.item_id)
    );

    let page_url =
      productUrl + "/share/product.php?c=" + otherPropsModal.info.item_id;
    if (window.ReactNativeWebView !== undefined && isIOS) {
      let link = encodeURIComponent(page_url);
      let title = serverResponse.config.shareProductSentence;
      var str = title + " " + link;
      var obj = { action: "whatsapp", value: str };
      window.ReactNativeWebView.postMessage(JSON.stringify(obj));
    } else {
      window.open(
        "http://api.whatsapp.com/send?text=" +
          serverResponse.config.shareProductSentence +
          " " +
          encodeURIComponent(page_url),
        "_blank",
        "location=yes,height=570,width=520,scrollbars=yes,status=yes"
      );
    }
  };

  const title_type = manager.getTitleType();

  const activedInteractivities = !props.modalRedux.loading
    ? otherPropsModal.info.markers.map((el) => el.type)
    : [];

  const space = width <= BREAKPOINT.lg ? "102px" : "92px";

  const additionalSpace = isIOS && width <= BREAKPOINT.lg ? "80px" : "0px";

  return (
    <Modal
      className="main-modal"
      // style={{ height: isMobile ? "95vh" : "inherit" }}
      centered
      isOpen={props.modalRedux.isModalOpen}
      toggle={() => {
        props.closeInteractivityModal();
      }}
      size="xl"
      onOpened={() => {
        // props.isModalOpen(true);
        if (otherPropsModal.disableSwipe !== undefined)
          otherPropsModal.disableSwipe();
      }}
      onClosed={() => {
        // this.props.isModalOpen(false);
        if (otherPropsModal.enableSwipe !== undefined)
          otherPropsModal.enableSwipe();
      }}
    >
      <div className="row h-100">
        <div className="col-12">
          <div
            className="row w-100 m-0"
            // toggle={}
            // close={closeBtn}
          >
            <div className="col-12 py-2">
              {!fakeServerConfig.hide_share_product && (
                <div style={styles.style_container_header}>
                  <div>
                    <img
                      src={Facebook}
                      className="share-icon ml-0"
                      style={styles.icon_style}
                      alt="facebook"
                      onClick={() => shareOnFacebook()}
                    />
                    <img
                      src={Whatsapp}
                      className="share-icon"
                      alt="whatsapp"
                      style={styles.icon_style}
                      onClick={() => shareOnWhatsapp()}
                    />
                  </div>
                  <img
                    onClick={() => props.closeInteractivityModal()}
                    style={styles.icon_style}
                    src={Close}
                    alt="close"
                  />
                </div>
              )}
            </div>
          </div>
          <hr className="my-0" style={styles.hr_style} />
          <ModalBody
            className="d-md-flex align-items-center"
            style={{ height: `calc(100% - ${space} - ${additionalSpace})` }}
          >
            {props.modalRedux.loading ? (
              <Row>
                <LoaderModal width="50vw" height="50vh" />
              </Row>
            ) : null}
            {/* CONTENUTO DINAMICO MODALE */}

            {!props.modalRedux.loading && (
              <div
                className={`row h-100 ${
                  width >= BREAKPOINT.lg ? "w-100" : ""
                } ${
                  otherPropsModal.type === "play" ? "w-100 m-0" : ""
                }  ${manager.getRowClass(props.modalRedux.otherProps.type)}`}
                style={{
                  justifyContent: "center",
                  // maxHeight: "65vh",
                  overflowY:
                    otherPropsModal.type !== "play"
                      ? width >= BREAKPOINT.lg
                        ? "hidden"
                        : "overlay"
                      : ""
                }}
              >
                <ContentModal {...props.modalRedux.otherProps} />
              </div>
            )}
          </ModalBody>
        </div>
      </div>
      <div
        className="row w-100 p-0 m-0"
        style={{
          borderBottomRightRadius: "15px",
          borderBottomLeftRadius: "15px",
          overflowY: "auto",
          position: "absolute",
          bottom: 0,
          height: width <= BREAKPOINT.lg ? "65px" : "50px"
        }}
      >
        <div className="col-12 w-100 p-0 m-0">
          {props.modalRedux.isProduct && (
            <ModalFooter
              style={{
                justifyContent:
                  width <= BREAKPOINT.lg ? "space-between" : "center",
                flexWrap: "nowrap",
                padding: 0,
                backgroundColor: "rgb(248,249,250)",
                border: 0,
                height: "100%",
                borderTop: "1px solid rgba(0,0,0,0.1)",
                overflowX: "hidden"
              }}
            >
              {Object.keys(title_type).map((el, index) => {
                return (
                  <React.Fragment key={`${el}_${index}`}>
                    <button
                      onClick={() => {
                        if (
                          document.getElementById(
                            `${el}-${otherPropsModal.info.item_id}`
                          )
                        )
                          document
                            .getElementById(
                              `${el}-${otherPropsModal.info.item_id}`
                            )
                            .click();
                      }}
                      style={{
                        backgroundColor: "#fff",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        width: `calc(100% / ${Object.keys(title_type).length})`,
                        flexDirection:
                          width <= BREAKPOINT.lg ? "column-reverse" : "row",
                        fontSize: "10px",
                        borderTop:
                          otherPropsModal.type === el
                            ? `3px solid ${serverResponse.config.secondary_color}`
                            : "",
                        textAlign: "center",
                        color:
                          otherPropsModal.type === el ||
                          activedInteractivities.includes(el)
                            ? `black`
                            : "lightgray",
                        fontWeight: otherPropsModal.type === el ? "bold" : "",
                        borderRadius: 0,
                        borderRight: "1px solid rgba(0,0,0,0.1)",
                        filter: activedInteractivities.includes(el)
                          ? "none"
                          : "opacity(0.5)",
                        borderBottomRightRadius:
                          el === "plus" ? "15px" : "unset"
                      }}
                      className={` h-100 m-0 btn custom_inter overlay p-0 ${
                        otherPropsModal.type === el ? "shadow" : ""
                      }`}
                    >
                      <p
                        style={{
                          color: otherPropsModal.type === el ? "black" : "grey",
                          marginRight: width <= BREAKPOINT.lg ? "0px" : "5px",
                          fontSize: "1.3em"
                        }}
                      >
                        {title_type[el]}{" "}
                      </p>

                      {!activedInteractivities.includes(el) ||
                      otherPropsModal.type === el ? (
                        <div
                          className={`${
                            otherPropsModal.type === el ? "vertical" : ""
                          } `}
                          style={{ width: "40px" }}
                        >
                          {iconsObj[el].component}
                        </div>
                      ) : (
                        activedInteractivities.includes(el) &&
                        otherPropsModal &&
                        otherPropsModal !== {} &&
                        !props.modalRedux.loading && (
                          <Marker
                            dynamic_price={
                              props.modalRedux.otherProps.dynamic_price
                            }
                            dynamic_info={
                              props.modalRedux.otherProps.dynamic_info
                            }
                            show_dynamic_price={
                              props.modalRedux.otherProps.show_dynamic_price
                            }
                            zoom={otherPropsModal.zoom}
                            disableSwipe={otherPropsModal.disableSwipe}
                            enableSwipe={otherPropsModal.enableSwipe}
                            key={index}
                            info={otherPropsModal.info}
                            markerInfo={
                              otherPropsModal.info.markers.filter(
                                (info) => info.type === el
                              )[0]
                            }
                            leafletSwipe={otherPropsModal.leafletSwipe}
                            navMarkers={otherPropsModal.navMarkers}
                            setSwipe={otherPropsModal.setSwipe}
                            isSwipeDisabed={otherPropsModal.isSwipeDisabed}
                            type={el}
                            custom_class={"custom_width_marker"}
                          />
                        )
                      )}
                    </button>
                  </React.Fragment>
                );
              })}
            </ModalFooter>
          )}
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    modalRedux: state.modal,
    fakeServer: state.fakeServer
  };
};

export default connect(mapStateToProps, {
  closeInteractivityModal
})(ModalComponent);
