import React, { useEffect, useState } from "react";
import { serverResponse } from "../../../../util/fakeServer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "reactstrap";
import { strings } from "../../../../util/data/strings";
import { connect } from "react-redux";
import actions from "../../../../redux/groceryList/action";
import { isModalOpen } from "../../../../redux/carousel/action";
import { NotificationManager } from "react-notifications";
import { language } from "../../../../languageProvider/language/language";
import {
  defaultLanguage,
  sendAnalyticsEvent
} from "../../../../helper/utility";
import { gaEventLabel } from "../../../../settings/gaConfig";
import useCurrentWidth from "../../../../hooks/widthHook";
import { BREAKPOINT } from "../../../../helper/constant";

const CartModal = (props) => {
  const [quantity, setQuantity] = useState(
    props.info.quantity_step ? props.info.quantity_step : 1
  );

  const currentWidth = useCurrentWidth();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    props.setVarietySelected(
      props.info.varieties.length > 0 ? props.info.varieties[0] : ""
    );
  }, [props.info]);

  const incrementQty = () => {
    setQuantity(
      (prev) => prev + (props.info.quantity_step ? props.info.quantity_step : 1)
    );
  };

  const decrementQty = () => {
    if (props.info.quantity_step) {
      if (quantity > props.info.quantity_step) {
        setQuantity((prev) => prev - props.info.quantity_step);
      }
    } else if (quantity > 1) {
      setQuantity((prev) => prev - 1);
    }
  };

  const toggleSaveTooltip = () => {
    setIsOpen(!isOpen);
  };

  const handleAddProduct = () => {
    let obj = Object.assign({}, props.info);
    obj.quantity = quantity;
    obj.done = false;
    obj.variety = props.varietySelected;

    console.log("THIS IS MY OBJ", obj);
    props.addProduct(obj);
    NotificationManager.success(
      language[defaultLanguage].controlla_la_tua_lista_della_spesa,
      language[defaultLanguage].prodotto_aggiunto,
      800
    );
    if (document.getElementById("selected-" + props.info.id) != null)
      document.getElementById("selected-" + props.info.item_id).style[
        "display"
      ] = "inline";
    sendAnalyticsEvent(
      gaEventLabel.add_to_grocery_list,
      String(props.info.item_id)
    );
  };

  return (
    <>
      <div
        style={{ fontFamily: "var(--font-family)", ...props.additionalStyle }}
        className={`${props.colCustom ||
          "col-6"} d-flex justify-content-around align-items-center `}
      >
        {!props.info.one_and_one_gratis && (
          <>
            <FontAwesomeIcon
              className="change-product-quantity-icon iconStyleModal iconL "
              icon={faMinus}
              onClick={decrementQty}
            />
            <h6>
              {quantity} {props.info.grocery_label}
            </h6>

            <FontAwesomeIcon
              className="change-product-quantity-icon iconStyleModal iconR"
              icon={faPlus}
              onClick={incrementQty}
            />
          </>
        )}

        {props.componentPrice}
      </div>
      <div className="col-6 p-0">
        {!props.info.one_and_one_gratis && (
          <>
            <button
              style={{
                borderRadius: "30px",
                padding: "8px",
                margin: 0,
                marginLeft: currentWidth >= BREAKPOINT.lg ? "15px" : "0",
                color: "white",
                fontWeight: "bold",
                fontSize: "0.7rem",
                fontFamily: "var(--font-family)",
                maxWidth: currentWidth >= BREAKPOINT.lg ? "200px" : "unset"
              }}
              disabled={
                props.info.stock !== "y" &&
                props.info.stock !== null &&
                serverResponse.config.manager_stock
              }
              id={"btnSave_" + props.info.item_id}
              className="btn btn-primary mx-0 px-0 w-100 text-center text-uppercase"
              onClick={handleAddProduct}
            >
              {language[defaultLanguage].aggiungi_alla_lista}
            </button>

            {props.info.stock === "t" && (
              <Tooltip
                placement="top"
                isOpen={this.state.saveTooltipOpen}
                target={"btnSave_" + props.info.item_id}
                toggle={toggleSaveTooltip}
              >
                {strings.saveBtnTooltip}
              </Tooltip>
            )}
          </>
        )}
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addProduct: (newProduct) => {
      dispatch(actions.addProduct(newProduct));
    },
    isModalOpen: (bool) => {
      dispatch(isModalOpen(bool));
    }
  };
};

export default connect(null, mapDispatchToProps)(CartModal);
