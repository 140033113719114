import { serverResponse } from "../util/fakeServer";
import DefaultManager from "./Default.manager";
import IperbimboManager from "./Iperbimbo.manager";
import InfoManager from "./Info.manager";
import ErgonManager from "./Ergon.manager";
import RudManager from "./Rud.manager";

const clientId = serverResponse.config.client_id;
const signboardId = serverResponse.config.signboard_id;

class ManagerFactory {
  static getManager() {
    switch (clientId) {
      case 7:
        return new IperbimboManager(signboardId);

      case 29:
        return new ErgonManager(signboardId);

      case 185:
        return new InfoManager(signboardId);

      case 2194:
        return new RudManager(signboardId);

      default:
        return new DefaultManager(signboardId);
    }
  }
}

export default ManagerFactory;
