var leaflets = null;

var isPreview = window.location.pathname.search("preview") !== -1;
var request_v = new XMLHttpRequest();
var request = new XMLHttpRequest();
var json_leaflet = null;
var v = 1;

global.preview_id = window.location.href.substring(
  window.location.href.lastIndexOf("/") + 1
);
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const id = urlParams.get("id");
global.preview_id = id;
request.open(
  "GET",
  `https://dev02.interattivo.net/it/interactive-flyer-json/${global.preview_id}`,
  false
);
request.send(null);

try {
  json_leaflet = JSON.parse(request.responseText);
} catch (e) {
  // window.open('https://new.volantinointerattivo.net', '_self');
  console.log(e.message);
}
leaflets = json_leaflet;


const lefletSwitch = () => {
  if (isPreview) {
    leaflets.client.config.adsense = false;
    leaflets.client.config.ga_active = false;
  }
  return leaflets.client;
};

export const serverResponse = lefletSwitch();
