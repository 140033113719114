import { defaultLanguage } from "../helper/utility";
import { language } from "../languageProvider/language/language";
import DefaultManager from "./Default.manager";

class RudManager extends DefaultManager {
  signboardId;

  constructor(signboardId) {
    super(signboardId);
    this.signboardId = signboardId;
  }

  getComponentCategory() {
    return "CategoriesDynamic";
  }

  isSearchProductVisible() {
    return true;
  }

  noAddCart() {
    return true;
  }

  getVisibleInteractivities() {
    return ["plus"];
  }

  getTitleType() {
    return {
      play: language[defaultLanguage].video,
      world: "Link",
      attachment: language[defaultLanguage].manuale,
      plus: language[defaultLanguage].dettaglio
    };
  }
  getDefaultFont() {
    return "M PLUS Rounded 1c, sans-serif";
  }
}

export default RudManager;
