import React, {
  Component
  // createRef
} from "react";
import Product from "../productsList/Product";

import { connect } from "react-redux";
import Cookies from "universal-cookie";
import {
  InputGroup,
  InputGroupAddon,
  Input,
  Button,
  // Label,
  Row,
  Col
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faBars,
  faFilter,
  faArrowUp
} from "@fortawesome/free-solid-svg-icons";

import "../../assets/css/custom.css";
import ReactGA from "react-ga";
import { serverResponse } from "../../util/fakeServer";

import { gaEventLabel } from "../../settings/gaConfig";
import { isIE, isEdge } from "react-device-detect";
import OffCanvas from "react-aria-offcanvas";
import { styles } from "./categories.styles";

import Adsense from "../leaflet/Adsense";
import { language } from "../../languageProvider/language/language";
import {
  defaultLanguage,
  getCustomActions,
  sendAnalyticsEvent
} from "../..//helper/utility";
import Select from "react-select";
import { getPricesAction } from "../../redux/price/price.action";

import { BREAKPOINT } from "../../helper/constant";
const DEFAULT_ITEMS = 12;

if (
  serverResponse.config.only_browsable ||
  serverResponse.config.hide_product_page
) {
  var path = window.location.pathname;
  if (path === "/categories") {
    window.location.href = "/leaflet";
  }
}

const openBanner = () => {
  if (serverResponse.config.internal_banner_click) {
    var id_prod_special = serverResponse.config.product_banner;
    if (document.getElementById("plus-" + id_prod_special)) {
      sendAnalyticsEvent(gaEventLabel.banner_click, String(id_prod_special));
      document.getElementById("plus-" + id_prod_special).click();
    }
  } else if (serverResponse.config.external_banner_click) {
    window.open(serverResponse.config.href_banner, "_blank");
  }
};

class CategoriesStatic extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.selectCategoryRef = React.createRef();
    this.selectSubCategoryRef = React.createRef();

    let page_has_video = false;
    let video_only_once = false;
    let video_mandatory_seconds = 0;
    let videos = [];
    let video_never = false;

    if (serverResponse.hasOwnProperty("video_categories")) {
      page_has_video = true;
      videos = serverResponse.video_categories.videos.map((i) => i.page);
      video_only_once = serverResponse.video_categories.show_only_once;
      video_mandatory_seconds =
        serverResponse.video_categories.mandatory_seconds;
    }

    const cookies = new Cookies();

    if (cookies.get("neverTutorial") !== undefined) video_never = true;
    this.state = {
      products: Object.values(serverResponse.interactivities),
      nameFilter: "",
      brandFilter: false,
      underpriceFilter: false,
      categoryFilter: "",
      subCategoryFilter: "",
      items: 0,
      hasMoreItems: true,
      showScrollUp: false,
      page_has_video: page_has_video,
      videos: videos,
      show_close_video: false,
      preroll_video_in_progress: false,
      video_only_once: video_only_once,
      video_page_number: null,
      video_page_item_id: null,
      countdown: video_mandatory_seconds,
      video_mandatory_seconds: video_mandatory_seconds,
      video_modal_open: false,
      video_url: "",
      video_muted: true,
      video_playing: false,
      video_never: video_never,
      loading: false,
      numberOfItem: null,
      sidebarFilterOpen: false,
      listType: "grid",
      subCategoryArr: [],
      itemsToRender: [],
      skulsArr: [],
      totalProduct: 0,
      globalHeigth: window.innerHeight,
      globalWidth: window.innerWidth,
      isFirstTime: true
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize);
    if (this.props.match.params.name !== undefined) {
      this.setState({ nameFilter: this.props.match.params.name });
    }

    const scrollTarget =
      this.state.globalWidth >= BREAKPOINT.lg
        ? document.querySelector(".wrapper_vm_container").parentNode
        : document.querySelector("#scrollable-div");

    scrollTarget.addEventListener("scroll", this.trackScrolling);

    if (serverResponse.config.ga_active) {
      ReactGA.pageview(
        `/${serverResponse.config.client_id}-${serverResponse.leaflet.id}/categorie`
      );
    }

    if (this.state.page_has_video && !this.state.video_never) {
      this.showPageVideo(-1, true);
    }
    if (this.props.match.params.item_id !== undefined) {
      if (document.getElementById("plus-" + this.props.match.params.item_id))
        document
          .getElementById("plus-" + this.props.match.params.item_id)
          .click();
    }
    if (this.props.match.params.item_id !== undefined) {
      this.setState({ items: this.state.products.length });
    }
  }
  componentWillUnmount() {
    const scrollTarget =
      this.state.globalWidth >= BREAKPOINT.lg
        ? document.querySelector(".wrapper_vm_container").parentNode
        : document.querySelector("#scrollable-div");
    scrollTarget.removeEventListener("scroll", this.trackScrolling);
  }

  resize = () => {
    this.setState({
      globalHeigth: window.innerHeight
    });
    this.setState({ globalWidth: window.innerWidth });
  };

  handleChangeName = (e) => {
    this.setState({
      nameFilter: e.target.value,
      items: [],
      hasMoreItems: true
    });
  };

  handleChangeBrand = (e) => {
    this.setState({
      brandFilter: e.target.checked,
      items: [],
      hasMoreItems: true
    });
    document.getElementById("scrollable-div").scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  };

  handleChangeCategory = (e) => {
    if (e) {
      this.setState({
        categoryFilter: e.value,
        subCategoryFilter: "",
        subCategoryArr: serverResponse.leaflet.categories.filter(
          (cat) => cat.category_name === e.value
        )[0].subcategories,
        // items: DEFAULT_ITEMS,
        hasMoreItems: true
      });

      this.selectSubCategoryRef.current.clearValue();

      this.myRef.current.scrollTo(0, 0);
    }
  };

  handleChangeCategoryMobile = (value) => {
    this.setState({
      categoryFilter: value,
      subCategoryFilter: "",
      subCategoryArr: serverResponse.leaflet.categories.filter(
        (e) => e.category_name === value
      )[0].subcategories,
      items: DEFAULT_ITEMS,
      hasMoreItems: true
    });

    this.myRef.current.scrollTo(0, 0);
  };

  handleChangeSubCategory = (e) => {
    if (e) {
      this.setState({
        subCategoryFilter: e.value,
        // items: DEFAULT_ITEMS,
        hasMoreItems: true
      });

      this.myRef.current.scrollTo(0, 0);
      if (isIE || isEdge) {
        document.getElementById("scrollable-div").scrollTop = 0;
      } else {
        document.getElementById("scrollable-div").scroll({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
      }
    }
  };
  handleChangeSubCategoryMobile = (value) => {
    this.setState({
      subCategoryFilter: value,
      items: DEFAULT_ITEMS,
      hasMoreItems: true
    });

    this.myRef.current.scrollTo(0, 0);
    if (isIE || isEdge) {
      document.getElementById("scrollable-div").scrollTop = 0;
    } else {
      document.getElementById("scrollable-div").scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }
  };

  productFilter = (product) => {
    let nameCheck = false;
    let categoryCheck = false;
    let subcategoryCheck = false;
    if (product.skul) {
      nameCheck = String(product.description)
        .toLowerCase()
        .includes(this.state.nameFilter.toLowerCase())
        ? true
        : false;

      categoryCheck =
        this.state.categoryFilter !== ""
          ? product.category.toLowerCase() ===
            this.state.categoryFilter.toLowerCase()
            ? true
            : false
          : true;
      subcategoryCheck =
        this.state.subCategoryFilter !== ""
          ? product.subcategory.toLowerCase() ===
            this.state.subCategoryFilter.toLowerCase()
            ? true
            : false
          : true;
    }
    return nameCheck && categoryCheck && subcategoryCheck;
  };

  resetFilters = () => {
    this.setState({
      nameFilter: "",
      brandFilter: false,
      underpriceFilter: false,
      categoryFilter: "",
      subCategoryFilter: "",
      subCategoryArr: [],
      items: [],
      hasMoreItems: true,
      skulsArr: [],
      itemsToRender: [],
      isFirstTime: true
    });

    this.selectCategoryRef.current.clearValue();
    this.selectSubCategoryRef.current.clearValue();

    document.getElementById("scrollable-div").scrollTop = 0;
  };

  resetFiltersMobile = () => {
    this.setState({
      nameFilter: "",
      brandFilter: false,
      underpriceFilter: false,
      categoryFilter: "",
      subCategoryFilter: "",
      items: DEFAULT_ITEMS,
      hasMoreItems: true
    });

    document.getElementById("product_").value = "";
    if (isIE || isEdge) {
      document.getElementById("scrollable-div").scrollTop = 0;
    } else {
      document.getElementById("scrollable-div").scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }
  };

  formatCat = (obj) => {
    let opt = [];
    obj.arr.map((element) =>
      opt.push({
        value: obj.key ? element[obj.key] : element,
        label: obj.key ? element[obj.key] : element
      })
    );
    return opt;
  };

  handleSearch = () => {
    // if (
    //   this.state.categoryFilter !== "" ||
    //   this.state.subCategoryFilter !== "" ||
    //   this.state.nameFilter.replace(/\s/g, "") !== ""
    // )
    this.setState(
      {
        isFirstTime: false,
        items: 0,
        itemsToRender: []
      },
      () => this.updateProducts()
    );
  };

  updateProducts = () => {
    this.setState(
      {
        items: this.state.items + 10
      },
      () => {
        let filteredProducts = this.state.products
          .filter((el) => !el.dynamic_info)
          .filter((i) => i.markers.filter((m) => m.type === "plus").length > 0)
          .filter((el) => el.skul !== undefined)
          .filter(this.productFilter);
        this.setState(
          {
            totalProduct: filteredProducts.length,
            itemsToRender: filteredProducts.slice(0, this.state.items)
          },
          () => this.retrieveSkuls(this.state.itemsToRender)
        );
      }
    );
  };

  retrieveSkuls = (arr, cb) => {
    let startFrom = this.state.skulsArr.length;
    this.setState(
      {
        skulsArr: arr.filter((el) => el.dynamic_price).map((el) => el.skul)
      },
      () => {
        console.log("********* STATE ITEMS", this.state.items);
        console.log(
          "********* SKUL ARR",
          this.state.skulsArr.slice(startFrom, this.state.skulsArr.length)
        );
        //REDUX

        this.props.getPricesAction(
          "",
          false,
          this.state.skulsArr.slice(startFrom, this.state.skulsArr.length)
        );
      }
    );
  };

  isBottom(el) {
    return el.scrollHeight - el.scrollTop - el.clientHeight < 1;
  }

  trackScrolling = () => {
    const scrollTarget =
      this.state.globalWidth >= BREAKPOINT.lg
        ? document.querySelector(".wrapper_vm_container").parentNode
        : document.querySelector("#scrollable-div");

    // console.log("SONO AL BOTTOM", wrappedElement);
    if (this.isBottom(scrollTarget)) {
      if (this.state.itemsToRender.length < this.state.totalProduct)
        this.updateProducts();

      document.removeEventListener("scroll", this.trackScrolling);
    }
  };

  render() {
    const { nameFilter, subCategoryArr, showScrollUp } = this.state;

    let subcategories = [];
    if (this.state.categoryFilter !== "") {
      serverResponse.leaflet.categories.map((element) => {
        if (element.category_name === this.state.categoryFilter) {
          subcategories = element.subcategories;
        }
        return false;
      });
    }

    let brandInput = "";
    let underpriceInput = "";
    let amostOneCustomCheckbox = false;

    const filtri = (
      <div className="row ma-0 d-none d-lg-block">
        <div
          className="col-sm-12"
          style={{
            paddingRight: "15px",
            paddingLeft: "15px",
            paddingBottom: "15px",
            position: "fixed",
            zIndex: "3",
            width: "calc(100% - 100px)",
            top: "50px"
          }}
        >
          <div className="roe-card-style">
            <div
              className="roe-card-body"
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
              <Row>
                <Col
                  md="4"
                  className="margin-mobile"
                  style={{
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <span className="input-group-text icon-input-group">
                        <FontAwesomeIcon icon={faSearch} />
                      </span>
                    </InputGroupAddon>
                    <Input
                      placeholder={language[defaultLanguage].prodotto}
                      id="product"
                      onChange={this.handleChangeName}
                      value={nameFilter}
                    />
                  </InputGroup>
                </Col>
                <Col md="4" className="margin-mobile">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <span className="input-group-text icon-input-group">
                        <FontAwesomeIcon icon={faBars} />
                      </span>
                    </InputGroupAddon>
                  </InputGroup>
                  <Select
                    ref={this.selectCategoryRef}
                    isSearchable={true}
                    name="Categorie"
                    options={this.formatCat({
                      arr: serverResponse.leaflet.categories,
                      key: "category_name"
                    })}
                    placeholder={serverResponse.config.label_category}
                    onChange={(e) => this.handleChangeCategory(e)}
                  />
                </Col>

                <Col
                  style={{ display: "flex" }}
                  md="4"
                  className="margin-mobile"
                >
                  <div style={{ flex: 2 }}>
                    <Select
                      ref={this.selectSubCategoryRef}
                      className="basic-single"
                      classNamePrefix="Seleziona sottocategoria<"
                      isSearchable={true}
                      placeholder={serverResponse.config.label_subcategory}
                      name="Sottocategorie"
                      options={this.formatCat({
                        arr: subCategoryArr
                      })}
                      onChange={(e) => this.handleChangeSubCategory(e)}
                    />
                  </div>
                  <button
                    // disabled={
                    //   this.state.categoryFilter === "" &&
                    //   this.state.subCategoryFilter === "" &&
                    //   this.state.nameFilter.replace(/\s/g, "") === ""
                    // }
                    className="btn btn-primary d-none d-sm-block"
                    style={{ marginLeft: "20px" }}
                    onClick={this.handleSearch}
                  >
                    {language[defaultLanguage].cerca}
                  </button>
                  <button
                    className="btn btn-light d-none d-sm-block"
                    style={{ marginLeft: "20px" }}
                    color="primary"
                    onClick={this.resetFilters}
                  >
                    {language[defaultLanguage].reset}
                  </button>
                </Col>
                <Col md="4" className="d-block d-md-none d-none">
                  <InputGroup>
                    <Button
                      style={{ marginLeft: "auto" }}
                      color="primary"
                      onClick={this.resetFilters}
                    >
                      {language[defaultLanguage].reset}
                    </Button>
                  </InputGroup>
                </Col>
              </Row>
              {amostOneCustomCheckbox && (
                <Row style={{ marginTop: "10px" }}>
                  <Col md="12">
                    {brandInput}
                    {underpriceInput}
                  </Col>
                </Row>
              )}
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div ref={this.myRef}>
        {filtri}

        <div
          id="scrollable-div"
          className="container-fluid styleScrollableCategories"
          style={{ overflow: "auto" }}
          onScroll={(e) => {
            if (e.target.scrollTop > 300) {
              if (!this.state.showScrollUp) {
                this.setState({
                  showScrollUp: true
                });
              }
            } else {
              if (this.state.showScrollUp) {
                this.setState({
                  showScrollUp: false
                });
              }
            }
          }}
        >
          {/* {isMobile && filtri} */}
          {/* SIDEBAR FILTRI SOLO MOBILE */}
          <OffCanvas
            className="shadow d-block d-lg-none"
            style={styles.sidebarContainer}
            isOpen={this.state.sidebarFilterOpen}
            onClose={() => {
              this.setState({
                sidebarFilterOpen: false
              });
            }}
            labelledby="menu-button"
          >
            <h4 style={styles.marginBottomClass}>
              {language[defaultLanguage].filtra_prodotti}
            </h4>
            <Input
              placeholder={language[defaultLanguage].cerca_un_prodotto}
              id="product_"
              onChange={this.handleChangeName}
              defaultValue={nameFilter}
            />
            <div style={styles.categoryContainer}>
              {serverResponse.leaflet.categories.map((element, key) => {
                return (
                  <div key={key}>
                    <p
                      className="pointer"
                      style={styles.categoryName(
                        this.state.categoryFilter === element.category_name
                          ? serverResponse.config.secondary_color
                          : "black"
                      )}
                      onClick={() => {
                        this.handleChangeCategoryMobile(element.category_name);
                      }}
                      value={element.category_name}
                    >
                      {element.category_name} ({element.subcategories.length})
                    </p>
                    {element.category_name === this.state.categoryFilter &&
                      subcategories.map((element, key) => {
                        return (
                          <p
                            className="pointer"
                            style={styles.subCategoryName(
                              this.state.subCategoryFilter === element
                                ? serverResponse.config.secondary_color
                                : "black"
                            )}
                            key={key}
                            value={element}
                            onClick={() => {
                              this.handleChangeSubCategoryMobile(element);
                            }}
                          >
                            {element}
                          </p>
                        );
                      })}
                  </div>
                );
              })}
            </div>
            <div className="mb-5">
              <Button
                color="primary"
                className="d-sm-block"
                style={{ borderRadius: "30px" }}
                onClick={() => this.handleSearch()}
                // disabled={
                //   this.state.nameFilter === "" &&
                //   this.state.brandFilter === false &&
                //   this.state.underpriceFilter === false &&
                //   this.state.categoryFilter === "" &&
                //   this.state.subCategoryFilter === ""
                // }
              >
                {language[defaultLanguage].cerca}
              </Button>
              <Button
                color="primary"
                className="d-sm-block"
                style={{ borderRadius: "30px" }}
                onClick={() => this.resetFiltersMobile()}
                disabled={
                  this.state.nameFilter === "" &&
                  this.state.brandFilter === false &&
                  this.state.underpriceFilter === false &&
                  this.state.categoryFilter === "" &&
                  this.state.subCategoryFilter === ""
                }
              >
                {language[defaultLanguage].reset_filtri}
              </Button>
            </div>
          </OffCanvas>

          {serverResponse.config.category_banner && (
            <div className="col-sm-12 d-none d-lg-flex text-center">
              <img
                alt="banner"
                onClick={openBanner}
                src={serverResponse.config.category_banner}
                style={{
                  cursor: "pointer",
                  maxWidth: "100%",
                  marginTop: "75px"
                }}
              />
            </div>
          )}

          {serverResponse.config.category_banner_mobile && (
            <div className="col-sm-12 d-flex d-lg-none">
              <img
                alt="banner"
                onClick={openBanner}
                src={serverResponse.config.category_banner_mobile}
                style={{ cursor: "pointer", maxWidth: "100%" }}
              />
            </div>
          )}
          {serverResponse.config.adsense && <Adsense />}
          <div
            id="scroller"
            style={{ paddingTop: "80px", paddingBottom: "80px" }}
            className="row ma-0  filtered-products justify-content-start"
          >
            {this.state.itemsToRender.map((element, key) => (
              <div
                key={`${element.id}_${key}`}
                className="col-sm-12 col-lg-6 col-xl-3 product_card_container pb-3"
              >
                <Product
                  fromProducts={true}
                  elementIndex={key}
                  dynamic_price={element.dynamic_price}
                  dynamic_info={element.dynamic_info}
                  info={getCustomActions().getInteractivity(element.item_id)}
                />
              </div>
            ))}
          </div>
          {this.state.itemsToRender.length === 0 && !this.state.isFirstTime && (
            <div className="col-12 custom-padding" style={{ padding: "5px" }}>
              <h3 className="noProducts">
                {
                  language[defaultLanguage]
                    .nessun_prodotto_trovato_per_i_parametri_di_ricerca_inseriti
                }
              </h3>
            </div>
          )}
          {this.state.isFirstTime && (
            <div className="col-12 custom-padding" style={{ padding: "5px" }}>
              <h3 className="noProducts">
                {language[defaultLanguage].effettua_ricerca}
              </h3>
            </div>
          )}
        </div>

        <div
          className="scroll-up-btn"
          style={styles.buttonScrollUpBtn(
            showScrollUp ? "flex" : "none",
            "white"
          )}
          onClick={() => {
            if (isIE || isEdge) {
              document.getElementById("scrollable-div").scrollTop = 0;
            } else {
              document.getElementById("scrollable-div").scroll({
                top: 0,
                left: 0,
                behavior: "smooth"
              });
            }
          }}
          id="arrowScrollTop"
        >
          <FontAwesomeIcon
            size="1x"
            style={styles.displayType(!showScrollUp && "none")}
            icon={faArrowUp}
          />
        </div>

        <div
          onClick={() => {
            this.setState({
              sidebarFilterOpen: true
            });
          }}
          className="filter-scroll-up d-flex d-lg-none"
          style={styles.filterScrollUp(
            serverResponse.config.secondary_color,
            showScrollUp ? "10" : "10"
          )}
        >
          <FontAwesomeIcon
            onClick={() => {
              this.setState({
                sidebarFilterOpen: true
              });
            }}
            size="1x"
            style={styles.displayType()}
            icon={faFilter}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, { getPricesAction })(CategoriesStatic);
