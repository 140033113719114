export const styles = {
  text: {
    // display: "inline",
    width: "100%",
    fontFamily: "var(--font-family)",
    fontSize: "0.9rem"
  },
  read_or_hide: {
    color: "var(--secondary-color)",
    fontWeight: "bold",
    cursor: "pointer"
  }
};
