import { defaultLanguage } from "../../helper/utility";
import { language } from "../../languageProvider/language/language";
import { serverResponse } from "../../util/fakeServer";
import {
  LOGIN_JWT_SUCCESS,
  LOGOUT_JWT,
  TOGGLE_JWT_MODAL,
  LOGIN_JWT_FAIL,
  START_JWT_LOADING,
  GET_JWT_PROFILE,
  GET_JWT_PROFILE_FAIL,
  TOGGLE_USER_MODAL,
  SUCCESS_SIGNUP_JWT,
  REGISTER_JWT_FAIL,
  END_JWT_LOADING
} from "../type";

const initState = {
  isAuthenticated: false,
  modalJwtOpen: false,
  loading: false,
  user: null,
  error: null,
  loadingProfile: true,
  modalUserOpen: false,
  success_signup: false,
  feedbackMessage: ""
};

export default function(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case START_JWT_LOADING:
      return {
        ...state,
        loading: true
      };
    case END_JWT_LOADING:
      return {
        ...state,
        loading: false
      };
    case LOGIN_JWT_SUCCESS:
    case GET_JWT_PROFILE:
      return {
        ...state,
        isAuthenticated: true,
        user: payload,
        loading: false,
        error: null,
        modalJwtOpen: false,
        loadingProfile: false,
        success_signup: false
      };
    case LOGIN_JWT_FAIL:
    case REGISTER_JWT_FAIL:
    case GET_JWT_PROFILE_FAIL:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        error: payload,
        loading: false,
        loadingProfile: false
      };
    case SUCCESS_SIGNUP_JWT:
      return {
        ...state,
        success_signup: true,
        loading: false,
        feedbackMessage: language[defaultLanguage].registrazione_success
      };
    case LOGOUT_JWT:
      return {
        ...state,
        isAuthenticated: false,
        modalJwtOpen: false,
        loading: false,
        user: null,
        error: null,
        loadingProfile: false,
        modalUserOpen: false
      };
    case TOGGLE_JWT_MODAL:
      return {
        ...state,
        modalJwtOpen: !state.modalJwtOpen
      };
    case TOGGLE_USER_MODAL:
      return {
        ...state,
        modalUserOpen: !state.modalUserOpen
      };
    default:
      return state;
  }
}
