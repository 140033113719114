import React, { PureComponent } from "react";
import "../../assets/css/product.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Row, Col, InputGroup, InputGroupAddon, Input } from "reactstrap";
import Facebook from "../../assets/images/icons/facebook.svg";
import Whatsapp from "../../assets/images/icons/whatsapp.svg";
import ShowPriceMarker from "../../components/leaflet/markers/ShowPriceMarker";
import { connect } from "react-redux";
import actions from "../../redux/groceryList/action";
import { openInteractivityModal } from "../../redux/modal/action";
import { isIOS } from "react-device-detect";
import { NotificationManager } from "react-notifications";
import { serverResponse } from "../../util/fakeServer";
import { gaEventLabel } from "../../settings/gaConfig";
import { language } from "../../languageProvider/language/language";
import { defaultLanguage, sendAnalyticsEvent } from "../../helper/utility";

import TitleModal from "../modal/modalbody/components/Title.modal";
import GalleryModal from "../modal/modalbody/components/Gallery.modal";
import Price from "../modal/modalbody/components/Price.modal";

import { Modal, ModalHeader, ModalBody } from "reactstrap";
import AddCartModal from "../modal/modalbody/components/AddCart.modal";

const productUrl = serverResponse.config.shareFlyerURL;

class Product extends PureComponent {
  state = {
    itemCarousel: 0,
    varietySelected:
      this.props.info &&
      this.props.info.varieties &&
      this.props.info.varieties !== undefined
        ? this.props.info.varieties[0]
        : "",
    saveTooltipOpen: false,
    tooltipPricesOpen: false,
    openModal: false
  };

  componentWillReceiveProps(props) {
    this.setState({
      quantity: props.info.quantity_step ? props.info.quantity_step : 1,
      varietySelected: props.info.varieties ? props.info.varieties[0] : ""
    });
  }

  componentDidMount() {
    this.setState({
      quantity: this.props.info.quantity_step
        ? this.props.info.quantity_step
        : 1
    });
  }

  navMarkers = (newMarker) => {
    var element = document.getElementById(
      newMarker + "-" + this.props.info.item_id
    );
    element.click();
  };

  incrementQty = () => {
    this.setState({
      quantity:
        this.state.quantity +
        (this.props.info.quantity_step ? this.props.info.quantity_step : 1)
    });
  };

  decrementQty = () => {
    if (this.props.info.quantity_step) {
      if (this.state.quantity > this.props.info.quantity_step) {
        this.setState({
          quantity: this.state.quantity - this.props.info.quantity_step
        });
      }
    } else if (this.state.quantity > 1) {
      this.setState({ quantity: this.state.quantity - 1 });
    }
  };

  shareOnFacebook = () => {
    sendAnalyticsEvent(
      gaEventLabel.share_product_facebook,
      String(this.props.info.item_id)
    );
    let fb_share_url = "https://www.facebook.com/share.php?u=";
    let page_url =
      productUrl + "/share/product.php?c=" + this.props.info.item_id;
    window.open(
      fb_share_url + encodeURIComponent(page_url),
      "_blank",
      "location=yes,height=570,width=520,scrollbars=yes,status=yes"
    );
  };

  toggle = () => {
    this.setState({
      openModal: !this.state.openModal
    });
  };
  shareOnWhatsapp = () => {
    sendAnalyticsEvent(
      gaEventLabel.share_product_whatsapp,
      String(this.props.info.item_id)
    );
    let page_url =
      productUrl + "/share/product.php?c=" + this.props.info.item_id;
    if (window.ReactNativeWebView !== undefined && isIOS) {
      let link = encodeURIComponent(page_url);
      let title = serverResponse.config.shareProductSentence;
      var str = title + " " + link;
      var obj = { action: "whatsapp", value: str };
      window.ReactNativeWebView.postMessage(JSON.stringify(obj));
    } else {
      window.open(
        "http://api.whatsapp.com/send?text=" +
          serverResponse.config.shareProductSentence +
          " " +
          encodeURIComponent(page_url),
        "_blank",
        "location=yes,height=570,width=520,scrollbars=yes,status=yes"
      );
    }
  };

  handleAddProduct = () => {
    let obj = Object.assign({}, this.props.info);
    obj.quantity = this.state.quantity;
    obj.done = false;
    obj.variety =
      !this.state.varietySelected || this.state.varietySelected === undefined
        ? ""
        : this.state.varietySelected;
    this.props.addProduct(obj);
    NotificationManager.success(
      language[defaultLanguage].controlla_la_tua_lista_della_spesa,
      language[defaultLanguage].prodotto_aggiunto,
      800
    );

    if (serverResponse.config.ga_active) {
      sendAnalyticsEvent(
        gaEventLabel.add_to_grocery_list,
        String(this.props.info.item_id)
      );
      sendAnalyticsEvent(
        gaEventLabel.open_product,
        String(this.props.info.item_id)
      );
    }
  };

  handleChangeVariety = (e) => {
    this.setState({
      varietySelected: e.target.value
    });
  };

  toggleSaveTooltip = () => {
    this.setState({ saveTooltipOpen: !this.state.saveTooltipOpen });
  };

  toggleTooltipPricesOpen = () => {
    this.setState({ tooltipPricesOpen: !this.state.tooltipPricesOpen });
  };

  setVarietySelected = () => {
    this.setState({ varietySelected: "" });
  };

  render() {
    const { info } = this.props;
    // let markers = this.props.info.markers
    //   .filter((el) => el.type === "plus")
    //   .map((element, index) => {
    //     return (
    //       <Marker
    //         from_product={true}
    //         key={index}
    //         info={this.props.info}
    //         markerInfo={element}
    //         leafletSwipe={this.props.leafletSwipe}
    //         dynamic_price={this.props.dynamic_price}
    //         dynamic_info={this.props.dynamic_info}
    //         show_dynamic_price={this.props.show_dynamic_price}
    //         type={element.type}
    //       />
    //     );
    //   });

    // const stock = {
    //   y: {
    //     text: "Disponibile",
    //     color: "green",
    //     icon: <i className="fas fa-check"></i>
    //   },
    //   n: {
    //     text:
    //       "Non disponibile" +
    //       (this.props.info.tdc !== null
    //         ? " - arrivo tra " + this.props.info.tdc + " giorni"
    //         : ""),
    //     color: "red",
    //     icon: <i className="fas fa-times"></i>
    //   },
    //   c: {
    //     text: this.props.info.available_from,
    //     color: "var(--secondary-color)",
    //     icon: <i className="far fa-clock"></i>
    //   }
    // };

    // var disp = stock[this.props.info.stock];

    // eslint-disable-next-line no-unused-vars
    let hidden_price_marker = [];
    if (this.props.info.hidden_price) {
      hidden_price_marker = this.props.info.markers.map((element, key) => {
        switch (element.type) {
          case "play": {
            return (
              <ShowPriceMarker
                showButton={true}
                key={key + 10}
                info={info}
                markerInfo={element}
                leafletSwipe={() => {}}
                navMarkers={this.navMarkers}
                setSwipe={() => {}}
              />
            );
          }
          default:
            return null;
        }
      });
    }

    const varieties = (
      <Row
        style={{
          minHeight: "0px",
          // marginBottom: "15px",
          opacity: this.props.info.varieties.length > 0 ? 1 : 0
        }}
      >
        <Col md="12">
          {/* <Label for="variety">Scegli:</Label>*/}
          <InputGroup className="p-0">
            {/*    <InputGroupAddon addonType="prepend"><span className="input-group-text icon-input-group"><FontAwesomeIcon icon={faBars} /></span></InputGroupAddon>*/}
            <InputGroupAddon addonType="prepend"></InputGroupAddon>
            <Input
              style={{ borderRadius: "6px" }}
              type="select"
              name="select"
              id="variety"
              value={this.state.varietySelected}
              onChange={this.handleChangeVariety}
            >
              {this.props.info.varieties.map((element, key) => {
                return (
                  <option key={key} value={element}>
                    {element}
                  </option>
                );
              })}
            </Input>
          </InputGroup>
        </Col>
      </Row>
    );

    // eslint-disable-next-line no-unused-vars
    let brandImg = "";
    if (
      serverResponse.config.brandImage !== "" &&
      this.props.info.pam === true
    ) {
      brandImg = (
        <img
          style={{ width: "30%" }}
          src={process.env.PUBLIC_URL + serverResponse.config.brandImage}
          alt="img"
        />
      );
    }

    // eslint-disable-next-line no-unused-vars
    let cardImg = "";
    if (
      serverResponse.config.underPriceImage !== "" &&
      serverResponse.config.underPriceImage !== null &&
      this.props.info.underpriced_product === true
    ) {
      cardImg = (
        <img
          style={{ width: "50%", marginTop: "10px" }}
          src={process.env.PUBLIC_URL + serverResponse.config.underPriceImage}
          alt="img"
        />
      );
    }

    // eslint-disable-next-line no-unused-vars
    let h6Class = "";
    // eslint-disable-next-line no-unused-vars
    let isLineThrough = false;
    if (Boolean(serverResponse.config.line_through) === true) {
      h6Class = "barrato";
      isLineThrough = true;
    }

    return (
      <>
        <div className="row w-100 m-0 p-0">
          <div className="col-6 container-fields text-right p-0">
            <GalleryModal
              customClassContainer={"m-0"}
              customClassCol={"p-1"}
              maxWidth={"220px"}
              gallery={[info.images ? info.images[0] : []]}
              arrows={false}
            />
          </div>
          {/* <div className="col-1 p-0"></div> */}
          <div className="col-6 py-3">
            <div className="col-12 container-fields mb-3 mt-2 p-0">
              {/* Testi */}
              <TitleModal
                lineHeight="1.2rem"
                fontSize="1rem"
                title={info.field1}
              />
              <p
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  fontSize: "0.9rem"
                  // textTransform: "uppercase"
                }}
              >
                {info.field2} {info.field3} {info.field4}...
              </p>
            </div>
            <p
              onClick={() => {
                this.props.openModal(
                  { ...this.props, type: "plus" },
                  info.type
                );
              }}
              style={{
                fontSize: "0.8rem",
                // textDecoration: "underline",
                color: "white",
                borderRadius: "30px",
                cursor: "pointer",
                width: "fit-content",
                padding: "10px",
                background: serverResponse.config.primary_color
              }}
            >
              {language[defaultLanguage].mostra_piu}
            </p>
            <div>{varieties}</div>

            <Price
              getPrice={false}
              left={0}
              col={false}
              // top={"55%"}
              fontSize="1.9rem"
              textAlign="left"
              styleContainer={{
                padding: 0,
                marginBottom: "5px",
                marginTop: "5px"
              }}
              {...info}
            />
          </div>
        </div>

        {/* {!info.focus &&
          !info.one_and_one_gratis &&
          !serverResponse.config.send_grocery_list_to_market &&
          !serverResponse.config.hide_grocery_list && (
            <div className=" m-0 row">
              <div className="col-12 p-0">
                <div className="row">
                  <AddCartModal
                    text_value="AGGIUNGI"
                    // colCustom={"col-12"}

                    justifyContent={"space-between"}
                    setVarietySelected={() => {}}
                    varietySelected={this.state.varietySelected}
                    info={info}
                    price={true}
                  />
                </div>
              </div>
            </div>
          )} */}

        <div>
          <Modal centered isOpen={this.state.openModal} toggle={this.toggle}>
            <ModalHeader>{language[defaultLanguage].condividi_con}</ModalHeader>
            <ModalBody>
              <Row className="custom-share-container">
                {!serverResponse.config.hide_share_product && (
                  <img
                    src={Facebook}
                    className="share-icon"
                    alt="facebook"
                    onClick={() => this.shareOnFacebook()}
                  />
                )}
                {!serverResponse.config.hide_share_product && (
                  <img
                    src={Whatsapp}
                    className="share-icon"
                    alt="whatsapp"
                    onClick={() => this.shareOnWhatsapp()}
                  />
                )}
              </Row>
            </ModalBody>
          </Modal>
        </div>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addProduct: (newProduct) => {
      dispatch(actions.addProduct(newProduct));
    },
    openModal: (obj, code) => {
      dispatch(openInteractivityModal(obj, code));
    }
  };
}

export default connect(null, mapDispatchToProps)(Product);
