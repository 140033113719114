import React, { Component } from "react";
import MyGroceryElement from "../components/myGroceryList/MyGroceryElement";
import PageTitle from "components/common/PageTitle";
import { Scrollbars } from "react-custom-scrollbars";
import MetaTags from "react-meta-tags";
import { Row, Col } from "reactstrap";

import axios from "axios";
import "../assets/css/custom.css";

import { idCampaignName, myGroceryListName } from "../helper/constant";
import { serverResponse } from "../util/fakeServer";
import ReactGA from "react-ga";
import { Link, withRouter } from "react-router-dom";
import { createRef } from "react";
import { defaultLanguage, getCustomActions } from "../helper/utility";
import { language } from "../languageProvider/language/language";
import ExportButton from "../components/exportButton/ExportButton";
// import { gaEventLabel } from '../settings/gaConfig';

class MyGroceryList extends Component {
  constructor(props) {
    super(props);
    this.back = createRef();
  }

  state = {
    list: []
  };

  componentDidMount() {
    var id = this.props.match.params.id;
    var api_url = getCustomActions().get_read_api_url(id);

    //axios per il caricamento della lista
    axios({
      method: "get",
      url: api_url,
      headers: {}
    })
      .then((res) => {
        let tmpProducts = getCustomActions().get_product_api_url(res);

        tmpProducts.sort((a, b) => {
          const [aDone, bDone] = [a.done, b.done];
          if (aDone === bDone) {
            const [aID, bID] = [parseInt(a.item_id), parseInt(b.item_id)];
            if (aID === -1) return +1;
            if (bID === -1) return -1;
            return aID - bID;
          }
          if (aDone === true) return +1;
          if (bDone === true) return -1;
          return 1;
        });

        this.setState({
          list: tmpProducts
        });
        getCustomActions().backToLeaflet(
          this.backToLeaflet,
          this.props.history
        );
      })
      .catch((error) => {
        console.log(error);
      });

    if (serverResponse.config.ga_active) {
      ReactGA.pageview(
        `/${serverResponse.config.client_id}-${serverResponse.leaflet.id}/lista-spesa-condivisa`
      );
    }
  }

  checkProduct = (e) => {
    let tmpProducts = this.state.list;
    tmpProducts[e.target.id].done = e.target.checked;
    tmpProducts.sort((a, b) => {
      const [aDone, bDone] = [a.done, b.done];
      if (aDone === bDone) {
        const [aID, bID] = [parseInt(a.item_id), parseInt(b.item_id)];
        if (aID === -1) return +1;
        if (bID === -1) return -1;
        return aID - bID;
      }
      if (aDone === true) return +1;
      if (bDone === true) return -1;
      return 1;
    });
    this.setState({
      list: tmpProducts
    });
  };

  sortItems = (tmpProducts, e) => {
    tmpProducts[e.target.id].done = e.target.checked;
    tmpProducts.sort((a, b) => {
      const [aDone, bDone] = [a.done, b.done];
      if (aDone === bDone) {
        const [aID, bID] = [parseInt(a.item_id), parseInt(b.item_id)];
        if (aID === -1) return +1;
        if (bID === -1) return -1;
        return aID - bID;
      }
      if (aDone === true) return +1;
      if (bDone === true) return -1;
      return 1;
    });
    return tmpProducts;
  };

  backToLeaflet = () => {
    localStorage.setItem(
      myGroceryListName + serverResponse.leaflet.code,
      JSON.stringify(this.state.list)
    );
    localStorage.setItem(
      idCampaignName,
      this.state.list ? this.state.list[0].id_campagna : ""
    );
    // alert('ok')
    // window.location = "/leaflet";
  };

  render() {
    let tot = 0;
    const items = this.state.list;
    items.map((element, key) => {
      tot += parseFloat(element.currentPrice);
      element.index = key;
      element.images = [];
      element.images.push({ image_file: element.image_file });
      return null;
    });
    tot = tot.toFixed(2);

    return (
      <div
        className={getCustomActions().get_list_container_class}
        style={{ overflow: "hidden", height: "100vh" }}
      >
        <MetaTags>
          <title>La mia lista della spesa</title>

          <meta
            property="og:url"
            content={serverResponse.config.serverPath + "/myGroceryList"}
          />
          <meta property="og:type" content="article" />
          <meta
            property="og:title"
            content="Lista della spesa | Volantino Interattivo"
          />
          <meta property="og:description" content="" />
          <meta property="og:image" content="./logo.png" />
        </MetaTags>
        <PageTitle title="myGroceryList" className="ml-15" />

        {this.props.location.search !== "?s=n" && (
          <Row>
            <Col
              sm="12"
              md={{ size: 6, offset: 3 }}
              className="my-grocery-card"
            >
              <Link
                ref={this.back}
                style={{ textDecoration: "none" }}
                to={"/leaflet?cart=1"}
                onClick={this.backToLeaflet}
              >
                <Row className="custom-grocery-back-btn">
                  <span style={{ textAlign: "center", width: "100%" }}>
                    TORNA AL VOLANTINO
                  </span>
                </Row>
              </Link>
            </Col>
          </Row>
        )}
        <Row>
          <Col sm="12" md={{ size: 6, offset: 3 }}>
            <Scrollbars
              autoHide
              ref="scrollbars"
              renderTrackHorizontal={(props) => (
                <div
                  {...props}
                  style={{ display: "none" }}
                  className="track-horizontal"
                />
              )}
              className="custom-my-grocery-scrollbar"
            >
              {this.state.list.map((element, i) => {
                return (
                  <MyGroceryElement
                    info={element}
                    key={i}
                    checkProduct={this.checkProduct}
                  />
                );
              })}
            </Scrollbars>
          </Col>
        </Row>
        <Row
          style={{
            position: "fixed",
            bottom: "0",
            right: "0",
            left: "0",
            marginLeft: "unset !important",
            marginRight: "unset !important"
          }}
        >
          <Col
            sm="12"
            md={{ size: 6, offset: 3 }}
            style={{ justifyContent: "space-around", display: "flex" }}
            className="my-grocery-card mb-2"
          >
            <ExportButton idParamUrl={this.props.match.params.id} type="pdf" />
            <ExportButton idParamUrl={this.props.match.params.id} type="csv" />
          </Col>
          {tot > 0 && (
            <Col
              sm="12"
              md={{ size: 6, offset: 3 }}
              className="my-grocery-card"
            >
              <Row
                className="custom-grocery-back-btn"
                style={{ cursor: "auto", marginBottom: "0" }}
              >
                <span
                  style={{ textAlign: "center", width: "100%", color: "#0008" }}
                >
                  {language[defaultLanguage].totale}: € {tot}
                </span>
              </Row>
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

// export default MyGroceryList;

export default withRouter(MyGroceryList);
