import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  InputGroup,
  InputGroupAddon,
  Button
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faLockOpen, faUser, faCheck } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import {
  loginJWT,
  toggleJWTModal,
  signupJWT
} from "../../redux/authJWT/action";
import Loader from "../common/Loader";
import { language } from "../../languageProvider/language/language";
import { defaultLanguage } from "../../helper/utility";

const widthIcon = 38;

const LoginModal = (props) => {
  const [form, setForm] = useState({
    email: "",
    password: "",
    name: "",
    surname: "",
    codice: "",
    address: null,
    phone: null
  });

  const refEmail = useRef(null);
  const refName = useRef(null);
  const refSurname = useRef(null);
  const refPassword = useRef(null);
  const refCodiceCliente = useRef(null);
  const [type, setType] = useState(1);

  const [showPassword, setShowPassword] = useState(false);
  const [feedMessage, setFeedMessage] = useState(false);
  const [errorReset, setErrorReset] = useState(null);

  const onChange = ({ name, value }) => {
    setForm({ ...form, [name]: value });
  };

  useEffect(() => {
    if (props.authJWT.modalJwtOpen) {
      document.addEventListener("keyup", handleKeyPress);
    }

    return () => {
      document.removeEventListener("keyup", handleKeyPress);
    };
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && props.authJWT.modalJwtOpen) {
      document.getElementById("login_button").click();
      //handleOnPress();
    }
  };

  const handleOnPress = () => {
    if (type === 1) {
      props.loginJWT(form);
    } else {
      props.signupJWT(form);
    }

    // setForm({
    //   email: "",
    //   password: "",
    //   name: "",
    //   surname: "",
    //   phone: "",
    //   address: ""
    // });
  };

  return (
    <Modal
      autoFocus={false}
      toggle={() => {
        props.toggleJWTModal();
      }}
      onClosed={() => {
        // this.props.isModalOpen(false);
        setForm({
          email: "",
          password: ""
        });
      }}
      centered
      isOpen={props.authJWT.modalJwtOpen}
    >
      <ModalHeader
    style={{backgroundColor: "#fafafa"}}>Login</ModalHeader>
      {!props.authJWT.loading && props.authJWT.success_signup && (
        <ModalBody>
          <h3 style={{ color: "green" }}>
            Email inviata con successo. Conferma per loggarti
          </h3>
        </ModalBody>
      )}
      <ModalBody
    style={{backgroundColor: "#fafafa"}}>
        {props.authJWT.loading && !props.authJWT.success_signup ? (
          <Loader
            containerWidth="20vw"
            containerHeight="20vh"
            background={"transparent"}
          />
        ) : (
          !props.authJWT.success_signup && (
            <>
              <form
                className="account-form"
                onSubmit={(evt) => {
                  evt.preventDefault();
                  handleOnPress();
                }}
              >
                <div
                  className={
                    "account-form-fields"
                  }
                >
                  <input
                    value={form.email}
                    ref={refEmail}
                    id="email"
                    name="email"
                    type="email"
                    placeholder={language[defaultLanguage].email}
                    required
                    onChange={(e) => {
                      onChange({ name: "email", value: e.target.value });
                    }}
                  />
                  <input
                    value={form.password}
                    ref={refPassword}
                    id="password"
                    name="password"
                    type="password"
                    placeholder={language[defaultLanguage].password}
                    required={true}
                    disabled={false}
                    onChange={(e) => {
                      onChange({ name: "password", value: e.target.value });
                    }}
                  />
                </div>
                {!feedMessage && !props.authJWT.feedbackMessage && (
                  <button className="btn-submit-form mt-3" type="submit">
                    {language[defaultLanguage].login}
                    {props.authJWT.loading && (
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    )}
                    {!!props.authJWT.error && (
                      <div style={{ color: "red" }}>
                        <p>{props.authJWT.error}</p>
                      </div>
                    )}
                    {!!errorReset && <div style={{ color: "red" }}>{errorReset}</div>}
                  </button>
                )}
                {feedMessage && (
                  <p style={{ color: "#0008", width: "320px", textAlign: "center" }}>
                    <>
                      {feedMessage}

                      <FontAwesomeIcon style={{ color: "green" }} icon={faCheck} />
                    </>
                  </p>
                )}
                {props.authJWT.feedbackMessage && (
                  <p style={{ color: "#0008", width: "320px", textAlign: "center" }}>
                    <>
                      {props.authJWT.feedbackMessage}
                      <FontAwesomeIcon style={{ color: "green" }} icon={faCheck} />
                    </>
                  </p>
                )}
              </form>

              <div onClick={() => setType(type === 1 ? 2 : 1)}>
                {type === 1
                  ? language[defaultLanguage].no_account
                  : language[defaultLanguage].already_account}
              </div>
              <InputGroup>
                <InputGroupAddon addonType="append">
                  <button
                    className="input-group-text"
                    style={{
                      height: `${widthIcon}px`,
                      width: `${widthIcon}px`
                    }}
                  >
                    <FontAwesomeIcon icon={faUser} />
                  </button>
                </InputGroupAddon>

                <input
                  value={form.email}
                  ref={refEmail}
                  id="email"
                  name="email"
                  type="email"
                  placeholder={language[defaultLanguage].email}
                  required
                  onChange={(e) => {
                    onChange({ name: "email", value: e.target.value });
                  }}
                />
              </InputGroup>
              {type === 2 && (
                <>
                  <InputGroup>
                    <InputGroupAddon addonType="append">
                      <button
                        className="input-group-text"
                        style={{
                          height: `${widthIcon}px`,
                          width: `${widthIcon}px`
                        }}
                      >
                        <FontAwesomeIcon icon={faUser} />
                      </button>
                    </InputGroupAddon>

                    <Input
                      ref={refName}
                      type="text"
                      name="search"
                      id="name_register"
                      placeholder={"name"}
                      onChange={(e) => {
                        onChange({ name: "name", value: e.target.value });
                      }}
                      value={form.name}
                    />
                  </InputGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="append">
                      <button
                        className="input-group-text"
                        style={{
                          height: `${widthIcon}px`,
                          width: `${widthIcon}px`
                        }}
                      >
                        <FontAwesomeIcon icon={faUser} />
                      </button>
                    </InputGroupAddon>

                    <Input
                      ref={refSurname}
                      type="text"
                      name="search"
                      id="surname_register"
                      placeholder={"surname"}
                      onChange={(e) => {
                        onChange({ name: "surname", value: e.target.value });
                      }}
                      value={form.surname}
                    />
                  </InputGroup>
                  {/* <InputGroup>
                    <InputGroupAddon addonType="append">
                      <button
                        className="input-group-text"
                        style={{
                          height: `${widthIcon}px`,
                          width: `${widthIcon}px`
                        }}
                      >
                        <FontAwesomeIcon icon={faUser} />
                      </button>
                    </InputGroupAddon>

                    <Input
                      ref={refPhone}
                      type="phone"
                      name="search"
                      id="phone_register"
                      placeholder={"phone"}
                      onChange={(e) => {
                        onChange({ name: "phone", value: e.target.value });
                      }}
                      value={form.phone}
                    />
                  </InputGroup> */}
                  {/* <InputGroup>
                    <InputGroupAddon addonType="append">
                      <button
                        className="input-group-text"
                        style={{
                          height: `${widthIcon}px`,
                          width: `${widthIcon}px`
                        }}
                      >
                        <FontAwesomeIcon icon={faUser} />
                      </button>
                    </InputGroupAddon>

                    <Input
                      ref={refAddress}
                      type="text"
                      name="address"
                      id="address_register"
                      placeholder={"address"}
                      onChange={(e) => {
                        onChange({ name: "address", value: e.target.value });
                      }}
                      value={form.address}
                    />
                  </InputGroup> */}
                </>
              )}
              <InputGroup className="mt-3">
                <InputGroupAddon
                  onClick={() => {
                    setShowPassword((el) => !el);
                  }}
                  addonType="append"
                >
                  <button
                    className="input-group-text"
                    style={{
                      height: `${widthIcon}px`,
                      width: `${widthIcon}px`
                    }}
                  >
                    <FontAwesomeIcon
                      icon={!showPassword ? faLock : faLockOpen}
                    />
                  </button>
                </InputGroupAddon>

                <input
                  value={form.password}
                  ref={refPassword}
                  id="password"
                  name="password"
                  type="password"
                  placeholder={language[defaultLanguage].password}
                  onChange={(e) => {
                    onChange({ name: "password", value: e.target.value });
                  }}
                />
              </InputGroup>
              {!!props.authJWT.error && (
                <div style={{ color: "red" }}>
                  <p>{props.authJWT.error}</p>
                </div>
              )}

              <div className="d-flex justify-content-end">
                <Button
                  id="login_button"
                  onClick={handleOnPress}
                  className="mt-3 text-rigth"
                >
                  {type === 1 ? "Login" : "Registrati"}
                </Button>
              </div>
            </>
          )
        )}
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    authJWT: state.authJWT
  };
};

export default connect(mapStateToProps, {
  loginJWT,
  toggleJWTModal,
  signupJWT
})(LoginModal);
