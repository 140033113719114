import { serverResponse } from "../util/fakeServer";
import DefaultManager from "./Default.manager";
import ReactGA from "react-ga";

class ErgonManager extends DefaultManager {
  signboardId;

  constructor(signboardId) {
    super(signboardId);
    this.signboardId = signboardId;
  }

  gaAnalyticsEvent(event, item) {
    const data_analytics = {
      category: "1",
      action: event,
      label: item
    };

    ReactGA.event(data_analytics);
  }

  getAnalyticsId() {
    switch (this.signboardId) {
      case 46:
        return "G-FE092Q4D3B";

      default:
        return serverResponse.config.ga_tracking_id;
    }
  }
}

export default ErgonManager;
