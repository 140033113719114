import React, { useEffect, useMemo, useState } from "react";
import { Input, InputGroup, InputGroupAddon } from "reactstrap";
import { language } from "../../languageProvider/language/language";
import { defaultLanguage } from "../../helper/utility";
import { openInteractivityModal } from "../../redux/modal/action";
import { Search } from "../../assets/images/icone";
import { serverResponse } from "../../util/fakeServer";
import { setSearchbarWidth } from "../../redux/carousel/action";
import { connect } from "react-redux";

const SearchBar = (props) => {
  const [term, setTerm] = useState("");
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (term.length > 2) {
      handleSearch();
    }
  }, [term]);

  const ELEMENTS = useMemo(() => {
    return Object.values(serverResponse.interactivities).filter(
      (el) => el.type === "product"
    );
  }, []);

  const handleSearch = () => {
    const filteredElement = ELEMENTS.filter((prod) => {
      const regex = new RegExp(term, "i");
      return regex.test(prod.description.toLowerCase());
    });

    setProducts(filteredElement);
  };

  return (
    <div style={props.style} className="container-fluid search_box_container">
      <div className="row">
        <div className="col-12">
          <InputGroup className="custom-search-bar-wrapper">
            <Input
              style={{ borderRadius: "30px" }}
              type="text"
              name="serach"
              id="search"
              placeholder={language[defaultLanguage].cerca_un_prodotto}
              //   defaultValue={this.state.searchText}
              onChange={(e) => setTerm(e.target.value)}
            />
            <InputGroupAddon addonType="append">
              <Search
                style={{
                  width: "30px",
                  position: "absolute",
                  right: "40px",
                  top: "30px",
                  zIndex: 999
                }}
              />
            </InputGroupAddon>
          </InputGroup>
        </div>
        <div className="col-12">
          {products.map((el, index) => {
            return (
              <div
                style={{
                  cursor: "pointer"
                }}
                onClick={() => {
                  props.setSearchbarW();
                  console.log("el", el);
                  props.openModal(
                    {
                      info: el,
                      type: "plus"
                    },
                    "plus"
                  );
                }}
                className="text-center"
                key={`${el.id}_${index}`}
              >
                <img
                  className="img-fluid"
                  style={{ width: "80%" }}
                  src={el.images[0].image_file}
                  alt={`${el.field1}`}
                />
                <p className="text-center" style={{ fontSize: "1rem" }}>
                  {el.field1}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    themeSetting: state.themeSetting.sidebarMiniValue,
    searchBarOpen: state.carousel.searchBarOpen
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setSearchbarW: () => {
      dispatch(setSearchbarWidth());
    },
    openModal: (obj, code) => {
      dispatch(openInteractivityModal(obj, code));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
