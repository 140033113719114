import React from "react";
import AdComponent from "../components/leaflet/Adsense";
import CookieConsentBanner from "../components/leaflet/CookieConsentBanner";
import { plusIcon, playIcon } from "../helper/constant";
import { defaultLanguage } from "../helper/utility";
import { language } from "../languageProvider/language/language";
import { serverResponse } from "../util/fakeServer";
// import CategoriesStatic from "../views/CategoriesStatic";
// import CategoriesStatic from "../components/Categories/CategoriesStatic";

class DefaultManager {
  signboardId;

  constructor(signboardId) {
    this.signboardId = signboardId;
  }

  getPlusIcon() {
    return plusIcon;
  }

  getPlayIcon() {
    return playIcon;
  }

  noAddCart() {
    return false;
  }

  getTitleType() {
    return {
      play: language[defaultLanguage].video,
      "hat-chef": language[defaultLanguage].ricetta,
      world: "Link",
      specs: language[defaultLanguage].scheda,
      info: language[defaultLanguage].curiosita,
      attachment: language[defaultLanguage].allegato,
      plus: language[defaultLanguage].dettaglio
    };
  }

  getMarkerIconClass() {
    return "";
  }

  getConsentBanner() {
    return <CookieConsentBanner />;
  }

  getVideoProps() {
    return {
      muted: true,
      controls: false
    };
  }

  getComponentCategory() {
    return "CategoriesStatic";
  }

  getHAdsense() {
    return 90;
  }

  getBannerComponent() {
    return <AdComponent />;
  }

  filterMarkers() {
    return null;
  }

  getStoreButton(link) {
    return null;
  }

  getRowClass(type) {
    return "";
  }

  getAnalyticsId() {
    return serverResponse.config.ga_tracking_id;
  }

  gaAnalyticsEvent(event, item) {
    return false;
  }

  getVisibleInteractivities() {
    return [
      "attachment",
      "plus",
      "carousel",
      "world",
      "play",
      "info",
      "hat-chef",
      "specs",
      "promo",
      "external_link"
    ];
  }

  isSearchProductVisible() {
    return true;
  }

  getCategoriesPlaceholder() {
    return {
      category: "Scegli una categoria",
      subcategory: "Scegli una sottocategoria"
    };
  }

  getDefaultFont() {
    return "M PLUS Rounded 1c, sans-serif";
  }
}

export default DefaultManager;
