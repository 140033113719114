import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import React from "react";
import { withRouter } from "react-router-dom";
import "./loading.css";
import { serverResponse } from "../util/fakeServer";
import { useLocation } from "react-router-dom";
import { allowPath } from "../helper/utility";

const Loading = (props) => {
  const location = useLocation();

  if (
    !serverResponse.config.allow_login ||
    (props.authJWT.isAuthenticated && !props.authJWT.loadingProfile) ||
    !serverResponse.config.only_logged
  ) {
    return <Redirect to="/leaflet" />;
  }

  if (
    serverResponse.config.allow_login &&
    serverResponse.config.only_logged &&
    !props.authJWT.isAuthenticated &&
    props.authJWT.loadingProfile &&
    !allowPath.includes(location.pathname)
  ) {
    return <Redirect to="/register" />;
  }

  return (
    <div>
      <div className="cube-wrapper">
        <div className="cube-folding">
          <span className="leaf1"></span>
          <span className="leaf2"></span>
          <span className="leaf3"></span>
          <span className="leaf4"></span>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  authJWT: state.authJWT
});

export default withRouter(connect(mapStateToProps)(Loading));
