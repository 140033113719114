import React from "react";
import { connect } from "react-redux";
import { defaultLanguage } from "../../../helper/utility";
import { language } from "../../../languageProvider/language/language";
import ReadMore from "../../common/ReadMore";

import GalleryModal from "./components/Gallery.modal";
import ListModal from "./components/List.modal";

const HatChefBody = ({ modalRedux }) => {
  const {
    otherProps: {
      markerInfo: { data }
    }
  } = modalRedux;

  return (
    <>
      <div className="col-12 col-lg-6" style={{ position: "relative" }}>
        <GalleryModal gallery={data.img} />
      </div>

      <div className="col-12 col-lg-6">
        <h6
          style={{
            fontWeight: "bold",
            marginBottom: "5px"
          }}
        >
          {language[defaultLanguage].ingredienti}
        </h6>
        <ListModal list={data.ingredients} />
      </div>
      <div className="col-12 mt-3">
        <h6
          style={{
            fontWeight: "bold",
            marginBottom: "5px"
          }}
        >
          {language[defaultLanguage].procedimento}
        </h6>
        <ReadMore limit={9999}>{data.recipe}</ReadMore>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    modalRedux: state.modal
  };
};
export default connect(mapStateToProps)(HatChefBody);
