import React, { useEffect, useRef, useState } from "react";
import { Redirect } from "react-router-dom";
import "./login.css";
import { connect } from "react-redux";
import { loginJWT, signupJWT } from "../redux/authJWT/action";
import { serverResponse } from "../util/fakeServer";
import LoadingPages from "./Loading.pages";
import * as services from "../services/resetPassword.services";
import { language } from "../languageProvider/language/language";
import { defaultLanguage } from "../helper/utility";
import { useHistory } from "react-router-dom";

function ResetPassword(props) {
  const [errorReset, setErrorReset] = useState(null);
  const [feedMessage, setFeedMessage] = useState(false);
  const [form, setForm] = useState({
    password: "",
    confirm_password: ""
  });
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const refPassword = useRef(null);
  const refConfirmPassword = useRef(null);

  const onChange = ({ name, value }) => {
    setForm({ ...form, [name]: value });
  };

  function validatePassword(password, confirmPassword) {
    setErrorReset(null);
    if (password.length < 8 || confirmPassword.length < 8) {
      setErrorReset("Lunghezza minima 8 caratteri");
      return false; // Password e conferma password devono contenere almeno 8 caratteri
    }

    if (password !== confirmPassword) {
      setErrorReset("Le password non corrispondono");
      return false; // Password e conferma password devono essere uguali
    }

    return true; // Validazione passata con successo
  }

  const resetAll = () => {
    setErrorReset(null);
    setFeedMessage(null);
  };
  const handleOnPress = () => {
    setLoading(true);
    resetAll();
    const isValid = validatePassword(form.password, form.confirm_password);
    console.log(isValid);

    if (isValid) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const password_reset_token = urlParams.get("k");
      const dataReset = {
        password_reset_token,
        password: form.password
      };

      services
        .setPassword(dataReset)
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              setFeedMessage("Password reimpostata con successo");
            }
          }
        })
        .catch((err) => {
          alert("CATCH", err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleKeyPress = (e) => {};

  useEffect(() => {
    document.addEventListener("keyup", handleKeyPress);

    return () => {
      document.removeEventListener("keyup", handleKeyPress);
    };
  }, []);

  if (
    !serverResponse.config.allow_login ||
    (props.authJWT.isAuthenticated && !props.authJWT.loadingProfile)
  ) {
    return <Redirect to="/leaflet" />;
  }

  if (props.authJWT.loadingProfile) {
    return <LoadingPages />;
  }

  return (
    <div className="global-wrapper">
      <div className="main-container">
        <header>
          <div className={"header-headings sign-in"}>
            <span>{language[defaultLanguage].resetta_psw}</span>
          </div>
        </header>
        <ul className="options" style={{ listStyle: "none", padding: 0 }}>
          <li className="active">{language[defaultLanguage].rec_psw}</li>
        </ul>
        <form
          className="account-form"
          onSubmit={(evt) => {
            evt.preventDefault();
            handleOnPress();
          }}
        >
          <div className={"account-form-fields login"}>
            <input
              value={form.password}
              ref={refPassword}
              id="password"
              name="password"
              type="password"
              placeholder="Password"
              required={true}
              onChange={(e) => {
                onChange({ name: "password", value: e.target.value });
              }}
            />
            <input
              value={form.confirm_password}
              ref={refConfirmPassword}
              id="confirm_password"
              name="confirm_password"
              type="password"
              placeholder="Confirm Password"
              required={true}
              onChange={(e) => {
                onChange({ name: "confirm_password", value: e.target.value });
              }}
            />
          </div>
          {!!(!feedMessage || !loading) && (
            <button className="btn-submit-form" type="submit">
              {language[defaultLanguage].resetta_psw}
              {props.authJWT.loading && (
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              )}
              {!!props.authJWT.error && (
                <div style={{ color: "red" }}>
                  <p>{props.authJWT.error}</p>
                </div>
              )}
              {!!errorReset && <div style={{ color: "red" }}>{errorReset}</div>}
            </button>
          )}
          {feedMessage && (
            <div>
              {feedMessage}
              <button
                className="btn-submit-form"
                onClick={() => history.push("/login")}
              >
                Vai al login
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    authJWT: state.authJWT
  };
};

export default connect(mapStateToProps, {
  loginJWT,
  signupJWT
})(ResetPassword);
