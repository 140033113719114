import React, { useRef, useEffect, useCallback, useState } from "react";
import { connect } from "react-redux";
import {
  leafLetIcon,
  columnIcon,
  searchIcon,
  pdfIcon,
  shareIcon,
  indexIcon,
  BREAKPOINT
} from "../../helper/constant";
import { setStatusOfMenu } from "../../redux/floatingMenu/action";
import { serverResponse } from "../../util/fakeServer";
import { useLocation, useHistory } from "react-router-dom";
import { setIndexCarousel } from "../../redux/carousel/action";
import ShareModal from "../common/ShareModal";
import { scrollToSlide } from "../../helper/utility";
import Fab, { AB } from "./patch";
import "./fab.css";
import useCurrentWidth from "../../hooks/widthHook";
import * as Icone from "../../assets/images/icone/index.js";

const WAIT_BEFORE_SHOW = 100;

const FloatingMenu = (props) => {
  const ref = useRef(null);
  const history = useHistory();
  const [isShown, setIsShown] = useState(false);
  const width = useCurrentWidth();

  const arrayToFilter = serverResponse.config.sidebar;

  const [openShareModal, setOpenShareModal] = useState(false);

  const { pathname } = useLocation();

  const {
    config: { primary_color, secondary_color }
  } = serverResponse;

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true);
    }, WAIT_BEFORE_SHOW);
    return () => {
      clearTimeout(timer);
      setIsShown(false);
    };
  }, [WAIT_BEFORE_SHOW, pathname]);

  const handleOnClick = useCallback(
    (value) => history.push({ pathname: value, from: true }),
    [history]
  );
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      props.setStatusOfMenu(false);
    }
  };

  const readerType =
    serverResponse.config.type === "leaflet"
      ? "Volantino"
      : serverResponse.config.type === "menu"
      ? "Menu"
      : "Catalogo";

  const arrayEl = [
    {
      id: "3",
      key: "pages",
      text: "Pagine",
      backgroundColor: pathname.includes("pages")
        ? secondary_color
        : primary_color,
      imgSrc: columnIcon,
      svg: <Icone.Pagine />,
      onClick: () => handleOnClick("/pages")
    },
    {
      id: "4",
      key: "pdf",
      text: "Pdf",
      backgroundColor: primary_color,
      imgSrc: pdfIcon,
      svg: <Icone.Pdf />,
      onClick: () => window.open(serverResponse.config.pdfPath)
    },
    {
      id: "5",
      key: "share",
      text: "Condividi",
      backgroundColor: primary_color,
      imgSrc: shareIcon,
      svg: <Icone.Condividi />,
      onClick: () => setOpenShareModal(true)
    },
    {
      id: "6",
      text: "Vai all'indice",
      backgroundColor: primary_color,
      key: "index",
      imgSrc: indexIcon,
      svg: <Icone.Categorie />,
      onClick: () => {
        handleOnClick("/leaflet");
        props.setIndexCarousel(0);
        if (
          (serverResponse.config.scrollVertical ||
            serverResponse.config.scrollHorizontal) &&
          width < BREAKPOINT.lg
        )
          scrollToSlide(serverResponse.leaflet.index ? 0 : 1);
      }
    },
    {
      id: "2",
      key: "products",
      text: "Prodotti",
      backgroundColor: pathname.includes("categories")
        ? secondary_color
        : primary_color,
      imgSrc: searchIcon,
      svg: <Icone.Prodotti />,
      onClick: () => handleOnClick("/categories")
    },
    {
      id: "1",
      key: "leaflet",
      text: readerType,
      backgroundColor: pathname.includes("leaflet")
        ? secondary_color
        : primary_color,
      imgSrc: leafLetIcon,
      svg: <Icone.Volantino />,
      onClick: () => handleOnClick("/leaflet")
    }
  ];

  const filterFromServer = arrayEl.filter((el) => {
    return arrayToFilter.some((f) => {
      return f === el.key || el.key === "index";
    });
  });

  const elementToMap = [
    ...new Set([arrayEl[0], arrayEl[5], ...filterFromServer])
  ];

  const mainButtonStyles = {};
  const actionButtonStyles = {};
  const style = {};
  const event = "click";
  return (
    <div
      style={{
        transitionDuration: "500s"
      }}
      className={`${isShown ? "d-flex" : "d-none"} d-lg-none`}
      ref={ref}
      onClick={() => props.setStatusOfMenu(!props.floatingMenu)}
    >
      {elementToMap.length > 1 ? (
        <Fab
          mainButtonStyles={mainButtonStyles}
          actionButtonStyles={actionButtonStyles}
          style={style}
          event={event}
          icon="fa fa-plus"
          alwaysShowTitle={true}
        >
          {elementToMap.map((el) => (
            <AB
              icon={el.imgSrc}
              text={el.text}
              key={el.id}
              onClick={el.onClick}
            >
              {el.svg}
              {/* <img src={el.imgSrc} alt={el.text} /> */}
            </AB>
          ))}
        </Fab>
      ) : null}
      <ShareModal open={openShareModal} setOpen={setOpenShareModal} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    floatingMenu: state.floatingMenu.isOpen
  };
};

const mapDispatchToProps = { setStatusOfMenu, setIndexCarousel };

export default connect(mapStateToProps, mapDispatchToProps)(FloatingMenu);
