import {
  SET_INDEX_LABEL,
  SET_CAROUSEL_INDEX,
  SET_SLIDE_MAX,
  IS_FROM_INPUT,
  SET_FIRST_LOAD,
  SET_ZOOM_LEVEL,
  SET_REF_ZOOM,
  SET_BUTTON_DISABLED,
  IS_MODAL_OPEN,
  IS_RIGHT_SIDEBAR_OPEN,
  SET_VERTICAL_PAGES,
  SET_SEARCHBAR_OPEN
} from "../type";

export const setIndexCarousel = (indexCar) => (dispatch) => {
  dispatch({
    type: SET_CAROUSEL_INDEX,
    payload: indexCar
  });
};

export const setButtonDisabled = (bool = true) => (dispatch) => {
  dispatch({
    type: SET_BUTTON_DISABLED,
    payload: bool
  });
};

export const isFromInput = (bool) => (dispatch) => {
  dispatch({
    type: IS_FROM_INPUT,
    payload: bool
  });
};

export const setIndexLabel = (indexLabelValue) => (dispatch) => {
  dispatch({
    type: SET_INDEX_LABEL,
    payload: indexLabelValue
  });
};

export const setSlideMax = (slideMax) => (dispatch) => {
  dispatch({
    type: SET_SLIDE_MAX,
    payload: slideMax
  });
};

export const setFirstLoad = () => (dispatch) => {
  dispatch({
    type: SET_FIRST_LOAD
  });
};

export const setZoomLevel = (level = 1) => (dispatch) => {
  dispatch({
    type: SET_ZOOM_LEVEL,
    payload: level
  });
};

export const setRefZoom = (ref) => (dispatch) => {
  dispatch({
    type: SET_REF_ZOOM,
    payload: ref
  });
};

export const isModalOpen = (bool) => (dispatch) => {
  dispatch({
    type: IS_MODAL_OPEN,
    payload: bool
  });
};

export const isSidebarRightOpen = (bool) => (dispatch) => {
  dispatch({
    type: IS_RIGHT_SIDEBAR_OPEN,
    payload: bool
  });
};

export const setVerticalPages = () => (dispatch) => {
  dispatch({
    type: SET_VERTICAL_PAGES
  });
};

export const setSearchbarWidth = () => (dispatch) => {
  dispatch({
    type: SET_SEARCHBAR_OPEN
  });
};
