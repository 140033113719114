import * as React from "react"
import { serverResponse } from "../../../util/fakeServer"

const Condividi = ({ stroke = serverResponse.config.primary_color, fill = 'none', strokeWidth = 2 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Livello 1"
    viewBox="0 0 85.04 85.04"
  >
    <path
      d="M68.12 42.31 51.9 58.75a2.23 2.23 0 0 1-3.81-1.57V49s-13.36-6.69-31.17 8.9c0 0 4.45-22.27 31.17-22.27v-7.77a2.23 2.23 0 0 1 3.79-1.59Z"
      className="cls-1"
    />
  </svg>
)
export default Condividi
