export const language = {
  it_IT: {
    svuota_carrello: "Svuota carrello",
    cancella_lista: "Cancella la lista",
    manuale: "Manuale",
    scheda: "Scheda",
    allegato: "Allegato",
    volantino: "Volantino",
    prodotti: "Prodotti",
    pagine: "Pagine",
    pdf: "PDF",
    catalogo: "Catalogo",
    schermo_intero: "Schermo Intero",
    condividi_volantino: "Condividi Volantino",
    prodotto: "Prodotto",
    scegli_una_categoria: "Scegli una categoria",
    scegli_una_sottocategoria: "Scegli una sottocategoria",
    reset: "Reset",
    aggiungi_alla_lista: "Aggiungi alla lista",
    aggiungi_al_carrello: "Aggiungi al carrello",
    aggiungi: "Aggiungi",
    mostra_altri_prodotti: "Mostra altri prodotti",
    nessun_prodotto_trovato_per_i_parametri_di_ricerca_inseriti:
      "Nessun Prodotto trovato per i parametri di ricerca inseriti",
    effettua_ricerca:
      "Effettua una ricerca per visualizzare i prodotti in catalogo",
    dettaglio: "Dettaglio",
    indietro: "Indietro",
    chiudi: "chiudi",
    scegli: "Scegli",
    prodotto_aggiunto: "Prodotto Aggiunto",
    controlla_la_tua_lista_della_spesa: "Controlla la tua lista della spesa",
    lista_della_spesa: "Lista della spesa",
    la_tua_lista_della_spesa: "La tua lista della spesa",
    elimina_dalla_lista: "Elimina dalla lista",
    elimina_dal_carrello: "Elimina dal carrello",
    condividi_lista: "Condividi",
    condividi_con: "Condividi con",
    totale: "Totale",
    svuota: "Svuota",
    inserisci_un_prodotto_non_presente: "Inserisci un prodotto non presente",
    nome_prodotto: "Nome prodotto",
    prezzo_stimato: "Prezzo stimato",
    cerca_un_prodotto: "Cerca prodotto",
    link_copiato_negli_appunti: "Link Copiato Negli appunti",
    curiosita: "Curiosità",
    video: "Video",
    ricetta: "Ricetta",
    ingredienti: "Ingredienti",
    procedimento: "Procedimento",
    indice: "Indice",
    menu: "Menù",
    video_tutorial: "Video Tutorial",
    condividi: "Condividi",
    filtra_prodotti: "Filtra prodotti",
    mostra: "Mostra",
    risultati: "risultati",
    sto_caricando: "Sto caricando",
    secondi: "secondi",
    sfoglia_volantino_tra: "Sfoglia il volantino tra",
    reset_filtri: "Reset filtri",
    descrizione: "Descrizione",
    carosello: "Carosello",
    per_quantita: "per quantità",
    offerta: "Offerta",
    scopri_il_prezzo: "Scopri il prezzo",
    scopri: "Scopri",
    attendi_scopri_prezzo: "Attendi qualche istante per scoprire il prezzo di",
    caratteristiche: "Caratteristiche",
    il_tuo_carrello: "Il tuo carrello",
    stiamo_generando_lista_spesa: "Stiamo generando la tua lista della spesa",
    visualizza_lista_spesa: "Visualizza la tua lista della spesa",
    elemento_copiato: "Elemento copiato",
    errore_nella_copia: "Errore nella copia",
    link_non_copiato: "Link non copiato",
    torna_al_volantino: "Torna al volantino",
    salva_come_immagine: "Salva come immagine",
    sei_sicuro_svuota_carrello: "Sei sicuro di voler svuotare il tuo carrello",
    annulla: "Annulla",
    conferma: "Conferma",
    vai_prodotti: "Vai a prodotti",
    cerca: "Cerca",
    effettua_login: "Effettua il login",
    crea_account: "Crea un account",
    resetta_psw: "Resetta password",
    login: "Accedi",
    registrati: "Registrati",
    rec_psw: "Recupera",
    telephone: "Telefono",
    indirizzo: "Indirizzo",
    nome: "Nome",
    cognome: "Cognome",
    benvenuto: "Benvenuto",
    aggiungi_non_presente: "Ricorda di prendere anche",
    email: "Email",
    no_account: "Non hai un account? Registrati ",
    already_account: "Hai già un account? Accedi",
    email_non_presente: "Email non presente",
    mostra_piu: "Mostra di più",
    codice_cliente: "Codice cliente",
    password: "password",
    completa_ordine: "Completa ordine",
    registrazione_success:
      "Registrazione effettuata con successo. Verifica la tua mail per poter accedere!"
  },
  en_EN: {
    svuota_carrello: "Empty cart",
    cancella_lista: "Delete list",
    manuale: "Manual",
    scheda: "Card",
    allegato: "Attachment",
    volantino: "Flyer",
    prodotti: "Products",
    catalogo: "Catalogue",
    pagine: "Pages",
    pdf: "PDF",
    schermo_intero: "Fullscreen",
    condividi_volantino: "Share Flyer",
    prodotto: "Product",
    scegli_una_categoria: "Choose a category",
    scegli_una_sottocategoria: "Choose a subcategory",
    reset: "Reset",
    aggiungi_alla_lista: "Add to list",
    aggiungi_al_carrello: "Add to cart",
    aggiungi: "Aggiungi",
    mostra_altri_prodotti: "Show more products",
    nessun_prodotto_trovato_per_i_parametri_di_ricerca_inseriti:
      "No Product found for search parameters",
    effettua_ricerca: "Do a search to view products in the catalog",
    dettaglio: "Detail",
    indietro: "Back",
    chiudi: "Close",
    scegli: "Choose",
    prodotto_aggiunto: "Product Added",
    controlla_la_tua_lista_della_spesa: "Check your shopping list",
    lista_della_spesa: "Shopping list",
    la_tua_lista_della_spesa: "Your shopping list",
    elimina_dalla_lista: "Delete from list",
    elimina_dal_carrello: "Remove from cart",
    condividi_lista: "Share",
    condividi_con: "Share with",
    totale: "Total",
    svuota: "Clear",
    inserisci_un_prodotto_non_presente: "Enter a non-existing product",
    nome_prodotto: "Product Name",
    prezzo_stimato: "Estimated Price",
    cerca_un_prodotto: "Search a Product",
    link_copiato_negli_appunti: "Link Copied To the clipboard",
    curiosita: "Curiosity",
    video: "Video",
    ricetta: "Recipe",
    ingredienti: "Ingredients",
    procedimento: "Proceedings",
    indice: "Index",
    menu: "Menù",
    video_tutorial: "Video Tutorial",
    condividi: "Share",
    filtra_prodotti: "Filter products",
    mostra: "Show",
    risultati: "results",
    sto_caricando: "loading",
    secondi: "seconds",
    sfoglia_volantino_tra: "Browse the flyer in",
    reset_filtri: "Reset filters",
    descrizione: "Description",
    carosello: "Carousel",
    per_quantita: "for quantity",
    offerta: "Offer",
    scopri_il_prezzo: "Show price",
    scopri: "Show",
    attendi_scopri_prezzo: "Wait a few moments to see the price of",
    caratteristiche: "Specs",
    il_tuo_carrello: "Your Shopping Cart",
    stiamo_generando_lista_spesa: "Generating your shopping list",
    visualizza_lista_spesa: "Show your shopping list",
    elemento_copiato: "Item copied",
    errore_nella_copia: "Copy error",
    link_non_copiato: "Link not copied",
    torna_al_volantino: "Back to flyer",
    salva_come_immagine: "Save as image",
    sei_sicuro_svuota_carrello: "Are you sure to clear your cart",
    annulla: "Cancel",
    conferma: "Confirm",
    vai_prodotti: "Go to products",
    cerca: "Search",
    effettua_login: "Please login",
    crea_account: "Create account",
    resetta_psw: "Reset password",
    login: "Login",
    registrati: "Register",
    rec_psw: "Recover",
    telephone: "Telephone",
    indirizzo: "Address",
    nome: "Name",
    cognome: "Surname",
    benvenuto: "Welcome",
    aggiungi_non_presente: "Add a product not present",
    email: "Email",
    no_account: "Don't have an account? Register ",
    already_account: "Do you already have an account? Sign in.",
    email_non_presente: "Email not present",
    mostra_piu: "Show more",
    codice_cliente: "Customer code",
    password: "password",
    completa_ordine: "Complete order",
    registrazione_success:
      "Registration successful. Please check your email to access!"
  },
  fr_FR: {
    svuota_carrello: "Vider le panier",
    cancella_lista: "Effacer la liste",
    manuale: "Manuel",
    scheda: "Carte",
    allegato: "Pièce jointe",
    volantino: "Dépliant",
    prodotti: "Produits",
    catalogo: "Catalogue",
    pagine: "Pages",
    pdf: "PDF",
    schermo_intero: "Plein écran",
    condividi_volantino: "Partager le dépliant",
    prodotto: "Produit",
    scegli_una_categoria: "Choisissez une catégorie",
    scegli_una_sottocategoria: "Choisissez une sous-catégorie",
    reset: "Réinitialiser",
    aggiungi_alla_lista: "Ajouter à la liste",
    aggiungi_al_carrello: "Ajouter au panier",
    aggiungi: "Ajouter",
    mostra_altri_prodotti: "Afficher plus de produits",
    nessun_prodotto_trovato_per_i_parametri_di_ricerca_inseriti:
      "Aucun produit trouvé pour les paramètres de recherche saisis",
    effettua_ricerca:
      "Effectuez une recherche pour afficher les produits du catalogue",
    dettaglio: "Détail",
    indietro: "Retour",
    chiudi: "Fermer",
    scegli: "Choisir",
    prodotto_aggiunto: "Produit ajouté",
    controlla_la_tua_lista_della_spesa: "Vérifiez votre liste de courses",
    lista_della_spesa: "Liste de courses",
    la_tua_lista_della_spesa: "Votre liste de courses",
    elimina_dalla_lista: "Supprimer de la liste",
    elimina_dal_carrello: "Supprimer du panier",
    condividi_lista: "Partager",
    condividi_con: "Partager avec",
    totale: "Total",
    svuota: "Vider",
    inserisci_un_prodotto_non_presente: "Saisissez un produit inexistant",
    nome_prodotto: "Nom du produit",
    prezzo_stimato: "Prix estimé",
    cerca_un_prodotto: "Rechercher un produit",
    link_copiato_negli_appunti: "Lien copié dans le presse-papiers",
    curiosita: "Curiosité",
    video: "Vidéo",
    ricetta: "Recette",
    ingredienti: "Ingrédients",
    procedimento: "Procédure",
    indice: "Index",
    menu: "Menu",
    video_tutorial: "Tutoriel vidéo",
    condividi: "Partager",
    filtra_prodotti: "Filtrer les produits",
    mostra: "Afficher",
    risultati: "résultats",
    sto_caricando: "Chargement en cours",
    secondi: "secondes",
    sfoglia_volantino_tra: "Feuilleter le dépliant parmi",
    reset_filtri: "Réinitialiser les filtres",
    descrizione: "Description",
    carosello: "Carrousel",
    per_quantita: "par quantité",
    offerta: "Offre",
    scopri_il_prezzo: "Découvrir le prix",
    scopri: "Découvrir",
    attendi_scopri_prezzo:
      "Veuillez patienter quelques instants pour découvrir le prix de",
    caratteristiche: "Caractéristiques",
    il_tuo_carrello: "Votre panier",
    stiamo_generando_lista_spesa: "Nous générons votre liste de courses",
    visualizza_lista_spesa: "Afficher votre liste de courses",
    elemento_copiato: "Élément copié",
    errore_nella_copia: "Erreur de copie",
    link_non_copiato: "Lien non copié",
    torna_al_volantino: "Retour au dépliant",
    salva_come_immagine: "Enregistrer en tant qu'image",
    sei_sicuro_svuota_carrello: "Êtes-vous sûr de vouloir vider votre panier",
    annulla: "Annuler",
    conferma: "Confirmer",
    vai_prodotti: "Accéder aux produits",
    cerca: "Rechercher",
    effettua_login: "Veuillez vous connecter",
    crea_account: "Créer un compte",
    resetta_psw: "Réinitialiser le mot de passe",
    login: "Connexion",
    registrati: "S'inscrire",
    rec_psw: "Récupérer",
    telephone: "Téléphone",
    indirizzo: "Adresse",
    nome: "Nom",
    cognome: "Prénom",
    benvenuto: "Bienvenue",
    aggiungi_non_presente: "Ajouter un produit non présent",
    email: "Email",
    no_account: "Vous n'avez pas de compte ? Inscrivez-vous",
    already_account: "Vous avez déjà un compte ? Connectez-vous",
    email_non_presente: "L'email n'est pas présent",
    mostra_piu: "Afficher plus",
    codice_cliente: "Code client",
    password: "mot de passe",
    completa_ordine: "Finaliser la commande",
    registrazione_success:
      " Inscription réussie. Veuillez vérifier votre courrier électronique pour accéder!"
  },
  de_DE: {
    svuota_carrello: "Warenkorb leeren",
    cancella_lista: "Liste löschen",
    manuale: "Handbuch",
    scheda: "Karte",
    allegato: "Anhang",
    volantino: "Flugblatt",
    prodotti: "Produkte",
    catalogo: "Katalog",
    pagine: "Seiten",
    pdf: "PDF",
    schermo_intero: "Vollbild",
    condividi_volantino: "Flugblatt teilen",
    prodotto: "Produkt",
    scegli_una_categoria: "Wähle eine Kategorie",
    scegli_una_sottocategoria: "Wähle eine Unterkategorie",
    reset: "Zurücksetzen",
    aggiungi_alla_lista: "Zur Liste hinzufügen",
    aggiungi_al_carrello: "In den Warenkorb legen",
    aggiungi: "Hinzufügen",
    mostra_altri_prodotti: "Mehr Produkte anzeigen",
    nessun_prodotto_trovato_per_i_parametri_di_ricerca_inseriti:
      "Kein Produkt gefunden für die eingegebenen Suchparameter",
    effettua_ricerca:
      "Führe eine Suche durch, um die Produkte im Katalog anzuzeigen",
    dettaglio: "Detail",
    indietro: "Zurück",
    chiudi: "Schließen",
    scegli: "Wählen",
    prodotto_aggiunto: "Produkt hinzugefügt",
    controlla_la_tua_lista_della_spesa: "Überprüfe deine Einkaufsliste",
    lista_della_spesa: "Einkaufsliste",
    la_tua_lista_della_spesa: "Deine Einkaufsliste",
    elimina_dalla_lista: "Aus der Liste entfernen",
    elimina_dal_carrello: "Aus dem Warenkorb entfernen",
    condividi_lista: "Teilen",
    condividi_con: "Teilen mit",
    totale: "Gesamt",
    svuota: "Leeren",
    inserisci_un_prodotto_non_presente:
      "Füge ein nicht vorhandenes Produkt ein",
    nome_prodotto: "Produktname",
    prezzo_stimato: "Geschätzter Preis",
    cerca_un_prodotto: "Produkt suchen",
    link_copiato_negli_appunti: "Link in die Zwischenablage kopiert",
    curiosita: "Wissenswertes",
    video: "Video",
    ricetta: "Rezept",
    ingredienti: "Zutaten",
    procedimento: "Verfahren",
    indice: "Index",
    menu: "Menü",
    video_tutorial: "Videoanleitung",
    condividi: "Teilen",
    filtra_prodotti: "Produkte filtern",
    mostra: "Zeigen",
    risultati: "Ergebnisse",
    sto_caricando: "Lade",
    secondi: "Sekunden",
    sfoglia_volantino_tra: "Blättern Sie im Flugblatt zwischen",
    reset_filtri: "Filter zurücksetzen",
    descrizione: "Beschreibung",
    carosello: "Karussell",
    per_quantita: "pro Menge",
    offerta: "Angebot",
    scopri_il_prezzo: "Preis entdecken",
    scopri: "Entdecken",
    attendi_scopri_prezzo: "Bitte warten Sie einen Moment, um den Preis von",
    caratteristiche: "Eigenschaften",
    il_tuo_carrello: "Ihr Warenkorb",
    stiamo_generando_lista_spesa: "Wir generieren Ihre Einkaufsliste",
    visualizza_lista_spesa: "Zeige Ihre Einkaufsliste",
    elemento_copiato: "Element kopiert",
    errore_nella_copia: "Fehler beim Kopieren",
    link_non_copiato: "Link nicht kopiert",
    torna_al_volantino: "Zurück zum Flugblatt",
    salva_come_immagine: "Als Bild speichern",
    sei_sicuro_svuota_carrello:
      "Sind Sie sicher, dass Sie Ihren Warenkorb leeren möchten",
    annulla: "Abbrechen",
    conferma: "Bestätigen",
    vai_prodotti: "Zu den Produkten",
    cerca: "Suche",
    effettua_login: "Einloggen",
    crea_account: "Konto erstellen",
    resetta_psw: "Passwort zurücksetzen",
    login: "Anmelden",
    registrati: "Registrieren",
    rec_psw: "Wiederherstellen",
    telephone: "Telefon",
    indirizzo: "Adresse",
    nome: "Name",
    cognome: "Nachname",
    benvenuto: "Willkommen",
    aggiungi_non_presente: "Füge ein nicht vorhandenes Produkt hinzu",
    email: "E-Mail",
    no_account: "Sie haben noch kein Konto? Registrieren Sie sich",
    already_account: "Sie haben bereits ein Konto? Melden Sie sich an",
    email_non_presente: "E-Mail nicht vorhanden",
    mostra_piu: "Mehr anzeigen",
    codice_cliente: "Kundennummer",
    password: "Passwort",
    completa_ordine: "Bestellung abschließen",
    registrazione_success:
      " Registrierung erfolgreich abgeschlossen. Bitte überprüfe deine E-Mail, um Zugriff zu erhalten!"
  },
  es_ES: {
    svuota_carrello: "Vaciar carrito",
    cancella_lista: "Borrar lista",
    manuale: "Manual",
    scheda: "Tarjeta",
    allegato: "Adjunto",
    volantino: "Folleto",
    prodotti: "Productos",
    catalogo: "Catálogo",
    pagine: "Páginas",
    pdf: "PDF",
    schermo_intero: "Pantalla completa",
    condividi_volantino: "Compartir folleto",
    prodotto: "Producto",
    scegli_una_categoria: "Elige una categoría",
    scegli_una_sottocategoria: "Elige una subcategoría",
    reset: "Restablecer",
    aggiungi_alla_lista: "Agregar a la lista",
    aggiungi_al_carrello: "Añadir al carrito",
    aggiungi: "Agregar",
    mostra_altri_prodotti: "Mostrar más productos",
    nessun_prodotto_trovato_per_i_parametri_di_ricerca_inseriti:
      "No se encontraron productos para los parámetros de búsqueda ingresados",
    effettua_ricerca:
      "Realiza una búsqueda para ver los productos del catálogo",
    dettaglio: "Detalle",
    indietro: "Atrás",
    chiudi: "Cerrar",
    scegli: "Elegir",
    prodotto_aggiunto: "Producto agregado",
    controlla_la_tua_lista_della_spesa: "Verifica tu lista de compras",
    lista_della_spesa: "Lista de compras",
    la_tua_lista_della_spesa: "Tu lista de compras",
    elimina_dalla_lista: "Eliminar de la lista",
    elimina_dal_carrello: " Eliminar del carrito",
    condividi_lista: "Compartir",
    condividi_con: "Compartir con",
    totale: "Total",
    svuota: "Vaciar",
    inserisci_un_prodotto_non_presente: "Ingresa un producto no presente",
    nome_prodotto: "Nombre del producto",
    prezzo_stimato: "Precio estimado",
    cerca_un_prodotto: "Buscar un producto",
    link_copiato_negli_appunti: "Enlace copiado al portapapeles",
    curiosita: "Curiosidad",
    video: "Video",
    ricetta: "Receta",
    ingredienti: "Ingredientes",
    procedimento: "Procedimiento",
    indice: "Índice",
    menu: "Menú",
    video_tutorial: "Tutorial en video",
    condividi: "Compartir",
    filtra_prodotti: "Filtrar productos",
    mostra: "Mostrar",
    risultati: "resultados",
    sto_caricando: "Cargando",
    secondi: "segundos",
    sfoglia_volantino_tra: "Navega por el folleto entre",
    reset_filtri: "Restablecer filtros",
    descrizione: "Descripción",
    carosello: "Carrusel",
    per_quantita: "por cantidad",
    offerta: "Oferta",
    scopri_il_prezzo: "Descubre el precio",
    scopri: "Descubrir",
    attendi_scopri_prezzo: "Espera un momento para ver el precio de",
    caratteristiche: "Características",
    il_tuo_carrello: "Tu carrito",
    stiamo_generando_lista_spesa: "Estamos generando tu lista de compras",
    visualizza_lista_spesa: "Ver lista de compras",
    elemento_copiato: "Elemento copiado",
    errore_nella_copia: "Error al copiar",
    link_non_copiato: "Enlace no copiado",
    torna_al_volantino: "Volver al folleto",
    salva_come_immagine: "Guardar como imagen",
    sei_sicuro_svuota_carrello:
      "¿Estás seguro de que quieres vaciar tu carrito",
    annulla: "Cancelar",
    conferma: "Confirmar",
    vai_prodotti: "Ir a los productos",
    cerca: "Buscar",
    effettua_login: "Inicia sesión",
    crea_account: "Crear cuenta",
    resetta_psw: "Restablecer contraseña",
    login: "Iniciar sesión",
    registrati: "Registrarse",
    rec_psw: "Recuperar",
    telephone: "Teléfono",
    indirizzo: "Dirección",
    nome: "Nombre",
    cognome: "Apellido",
    benvenuto: "Bienvenido",
    aggiungi_non_presente: "Agregar un producto no presente",
    email: "Correo electrónico",
    no_account: "¿No tienes una cuenta? Regístrate",
    already_account: "¿Ya tienes una cuenta? Iniciar sesión",
    email_non_presente: "Correo electrónico no presente",
    mostra_piu: "Mostrar más",
    codice_cliente: "Código de cliente",
    password: "contraseña",
    completa_ordine: "Completa pedido",
    registrazione_success:
      "¡Registro completado exitosamente! ¡Verifica tu correo electrónico para acceder!"
  }
};
